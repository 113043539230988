import React from 'react';
import { useTranslation } from 'react-i18next';

import { useUserDocuments } from '../../../../hooks/useUserDocuments';
import PrimaryButton from '../../../Buttons/PrimaryButton/PrimaryButton';
import SecondaryButton from '../../../Buttons/SecondaryButton/SecondaryButton';
import Loader from '../../../Loader';

import './styles.scss';

const DocumentsList = ({ orderId, withFilters = true }) => {
  const { t } = useTranslation();
  const {
    isLoading,
    errorWhileLoading,
    rows,
    filteredRows,
    filters,
    updateFilter,
    FEFilters,
    updateFEFilter,
    resetFilter,
    handleFilterClick,
  } = useUserDocuments({
    orderId,
  });

  return (
    <div>
      <div className="content_top documents-filter">
        <div className="left_data">
          <div className="search_field">
            <input
              type="search"
              name="search"
              placeholder={t('search_documents')}
              value={FEFilters.name}
              onChange={(event) => {
                updateFEFilter('name', event.target.value);
              }}
            />
          </div>
          <div className="search_field">
            <select
              name="type"
              id="type"
              value={FEFilters.type}
              onChange={(event) => {
                updateFEFilter('type', event.target.value);
              }}
            >
              <option value="" selected disabled>
                {t('common.filter_type')}
              </option>
              <option value="listOfPDFInvoices">{t('Fatture')}</option>
              <option value="listOfPDFPackingSlips">{t('Documento di trasporto')}</option>
            </select>
          </div>
          {withFilters ? (
            <>
              <div className="search_field date">
                <span>{t('common.date_from')}:</span>
                <input
                  type="date"
                  name="startDate"
                  placeholder="Start date"
                  value={filters.startDate}
                  onChange={(event) => {
                    updateFilter('startDate', event.target.value);
                  }}
                />
              </div>
              <div className="search_field date">
                <span>{t('common.date_to')}:</span>
                <input
                  type="date"
                  name="endDate"
                  placeholder="End date"
                  value={filters.endDate}
                  onChange={(event) => {
                    updateFilter('endDate', event.target.value);
                  }}
                />
              </div>
            </>
          ) : null}
          <div className="search_field date">
            <PrimaryButton onClick={handleFilterClick}>{t('common.filter')}</PrimaryButton>
            <SecondaryButton onClick={resetFilter}>{t('common.filter_reset')}</SecondaryButton>
          </div>
        </div>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="content_data" id="documents-table">
          <div className="document_table_header">
            <div className="document_table_row">
              <div className="document_table_title">{t('Documenti')}</div>
              <div className="document_table_title type">{t('Tipo di documento')}</div>
              <div className="document_table_title"></div>
            </div>
          </div>
          <div className="document_table_body">
            {errorWhileLoading ? (
              <div className="document_table_row">
                <div className="error">{t('documents_could_not_be_loaded')}</div>
              </div>
            ) : rows.length > 0 ? (
              filteredRows.map((item, index) => {
                return (
                  <div className="document_table_row" key={index}>
                    <div className="document_table_column">{item.name}</div>
                    <div className="document_table_column type">
                      {item.document_type === 'listOfPDFInvoices'
                        ? t('Fatture')
                        : item.document_type === 'listOfPDFPackingSlips'
                          ? t('Documento di trasporto')
                          : null}
                    </div>
                    <div className="document_table_column download">
                      <a href={item.current_path} download={item.name}>
                        {t('Download_documenti')}
                      </a>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="document_table_row">{t('Nessun documento è stato trovato')}</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default DocumentsList;
