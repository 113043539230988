// import InfiniteScroll from "react-infinite-scroll-component";
import Multiselect from 'multiselect-react-dropdown';
import { React, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import { useSelector } from 'react-redux';
import { Link, useSearchParams } from 'react-router-dom';

import axios from 'axios';

import { customProductPaths } from '../../providers/TranslatedSlugProvider';
import { useTranslatableSearchParams, useTranslateAndReplaceUrlWhenLanguageChanged } from '../../utils/hooks';
import { getTranslated } from '../../utils/translations';

import { CUSTOM_PRODUCT_IMAGE_SRC } from './custom_product';
import Product_component from './product_component';

// import Accordion from "react-bootstrap/Accordion";
let deleteTimeout;

// const translatableSearchParamsMap = {
//   q: { en: 'q', it: 'q' },
//   fibra: { en: 'fibra', it: 'fibra' },
//   ne: { en: 'ne', it: 'ne' },
//   nm: { en: 'nm', it: 'nm' },
//   utilizzo: { en: 'utilizzo', it: 'utilizzo' },
//   finezza: { en: 'finezza', it: 'finezza' },
// };
const Category = (props) => {
  // useTranslateAndReplaceUrlWhenLanguageChanged(props, translatableSearchParamsMap);
  const dataUser = useSelector((state) => state?.auth?.userDataDetail);

  var [results, setResults] = useState([]);
  const [hasCustomProducts, setHasCustomProducts] = useState(false);
  const [filters, setFilters] = useState([]);
  const [nome_articolo, setMome_articolo] = useState([]);
  const [finezza, setFinezza] = useState([]);
  const [nm, setNm] = useState([]);
  const [ne, setNe] = useState([]);
  const [colore, setColore] = useState([]);
  const [utilities, setUtilities] = useState([]);
  const [fibra, setFibra] = useState([]);
  const [certificazioni, setCertificazioni] = useState([]);
  const [caratteristiche, setCaratteristiche] = useState([]);
  const [tecniche_produttive, setTecniche_produttive] = useState([]);
  const [cura_del_capo, setCura_del_capo] = useState([]);
  const [sostenibilita, setSostenibilita] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [searchParams] = useSearchParams();
  const [active_filters, Setactive_filters] = useState('active');
  const { t, i18n } = useTranslation();
  const userDataDetail = useSelector((state) => state?.auth?.userDataDetail);
  if (userDataDetail?.view_catalog === false) {
    window.location.href = '/';
  }

  const [innerWidth, SetinnerWidth] = useState(window.innerWidth);
  const q = searchParams.get('q');
  const showingSearchResult = typeof q === 'string' || q instanceof String;

  const getAllFilters = () => {
    axios.post('v1/product_list/productfilters').then((response) => {
      setFilters(response?.data?.result?.data);
    });

    setFibra([searchParams.get('fibra')]);
    if (searchParams.get('ne')) setNm([searchParams.get('ne')]);
    if (searchParams.get('nm')) setNe([searchParams.get('nm')]);
    if (searchParams.get('utilizzo')) setUtilities([searchParams.get('utilizzo')]);
    setFinezza([searchParams.get('finezza')]);
  };

  const manageFilters = (value, filter, filter_values) => {
    if (!filter_values.includes(value)) {
      filter([...filter_values, value]);
    } else {
      const data = filter_values.filter(function (e) {
        return e !== value;
      });
      filter([...data]);
    }
  };

  useEffect(() => {
    document.getElementById('home_page_class')?.classList.remove('home_page');
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    getAllFilters();
    if (innerWidth <= 767) {
      Setactive_filters('');
    }
  }, []);

  useEffect(() => {
    setResults([]);
    setPage(1);
    setHasMore(true);
    fetchMoreItems(1);
  }, [
    nome_articolo,
    finezza,
    nm,
    ne,
    colore,
    fibra,
    utilities,
    certificazioni,
    caratteristiche,
    tecniche_produttive,
    cura_del_capo,
    sostenibilita,
  ]);

  const fetchMoreItems = (page) => {
    clearTimeout(deleteTimeout);

    deleteTimeout = setTimeout(() => {
      axios
        .post(
          `/v1/product_list?page=${page}`,
          {
            nome_articolo: nome_articolo.toString(),
            finezza: finezza.toString(),
            nm: nm.toString(),
            ne: ne.toString(),
            colore: colore.toString(),
            utilities: utilities.toString(),
            fibra: fibra.toString(),
            biofil: certificazioni.includes('biofil') ? 'si' : '',
            bci: certificazioni.includes('bci') ? 'si' : '',
            gots: certificazioni.includes('gots') ? 'si' : '',
            'oeko-tex': certificazioni.includes('oeko-tex') ? 'si' : '',
            caratteristiche: caratteristiche.toString(),
            tecniche_produttive: tecniche_produttive.toString(),
            cura_del_capo: cura_del_capo.toString(),
            sostenibilita: sostenibilita.toString(),
            q: q || '',
          },
          {
            headers: {
              Authorization: `Bearer ${dataUser?.token}`,
            },
          }
        )
        .then((response) => {
          setTotal(response?.data?.result?.data?.total);
          if (response?.data?.result?.data?.data?.length == 0) {
            setHasMore(false);
          }
          setPage(page + 1);
          setResults((s) => [...s, ...response?.data?.result?.data?.data]);
          setHasCustomProducts(Boolean(response?.data?.result?.hasCustomProducts));
        })
        .catch((error) => console.error(error));
    }, 1500);
  };

  const filter_block = (id) => {
    document.getElementById(id).classList.toggle('filter_open');
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    <div className="main_content category">
      <div className="category_column">
        <div
          className="filter_mobile"
          onClick={(e) => {
            Setactive_filters(active_filters == 'active' ? '' : 'active');
          }}
        >
          <div className="link">
            <a
              href="javascript:void(0)"
              onClick={(e) => {
                Setactive_filters(active_filters == 'active' ? '' : 'active');
              }}
            >
              {t('filtri')}{' '}
            </a>
          </div>
        </div>
        <div className={' sidebar_section ' + active_filters}>
          <div className="sidebar_title">
            <h3>{t('filtri')}</h3>
            <span
              onClick={(e) => {
                Setactive_filters('');
              }}
              className="close_filter"
            >
              {t('chiudi')}
            </span>
          </div>

          {filters.length == 0 ? (
            <div className="filter_color_loader">
              <div className="loader"></div>
            </div>
          ) : (
            <div className="filter_section">
              <div className="filter_item" id="articolo">
                <div className="title" onClick={() => filter_block('articolo')}>
                  {t('Nome cartella')}
                </div>
                <div className="content size" style={{ marginTop: 15, marginBottom: 15 }}>
                  <Multiselect
                    isObject={false}
                    selectedValues={nome_articolo} // Preselected value to persist in dropdown
                    onSelect={(selectedList, selectedItem) => {
                      setMome_articolo([...selectedList]);
                    }} // Function will trigger on select event
                    onRemove={(selectedList, selectedItem) => {
                      setMome_articolo([...selectedList]);
                    }} // Function will trigger on select event
                    options={filters?.nome_articolo?.map((data) => {
                      return data.value[0].toUpperCase() + data.value.substring(1).toLowerCase();
                    })}
                  />

                  {/*<ul className="scroll_fix scroll_fix_with_scroll">*/}
                  {/*    {filters?.nome_articolo?.map((data) => (*/}
                  {/*        <li className={nome_articolo.includes(data.value) ? "active" : ""}*/}
                  {/*            key={data.value}*/}
                  {/*            onClick={() => manageFilters(data.value, setMome_articolo, nome_articolo)}>*/}
                  {/*            <a href="javascript:void(0)">{data.value}</a>*/}
                  {/*        </li>))}*/}
                  {/*</ul>*/}
                </div>
              </div>
              <div className="filter_item" id="finezza">
                <div className="title" onClick={() => filter_block('finezza')}>
                  {t('finezza')}
                </div>
                <div className="content size">
                  <ul className="scroll_fix">
                    {filters?.finezza?.map((data) => (
                      <li
                        className={finezza.includes(data.value) ? 'active' : ''}
                        key={data.value}
                        onClick={() => manageFilters(data.value, setFinezza, finezza)}
                      >
                        <a href="javascript:void(0)">{data.value}</a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="filter_item" id="nm">
                <div className="title" onClick={() => filter_block('nm')}>
                  {t('nm')}
                </div>
                <div className="content size">
                  <Multiselect
                    isObject={false}
                    selectedValues={nm} // Preselected value to persist in dropdown
                    onSelect={(selectedList, selectedItem) => {
                      setNm([...selectedList]);
                    }} // Function will trigger on select event
                    onRemove={(selectedList, selectedItem) => {
                      setNm([...selectedList]);
                    }} // Function will trigger on select event
                    options={filters?.nm?.map((data) => {
                      return data.value;
                    })}
                  />

                  {/*<ul className="scroll_fix scroll_fix_with_scroll">
                                                {filters?.nm?.map((data) => (
                                                    <li className={nm.includes(data.value) ? "active" : ""}
                                                        key={data.value}
                                                        onClick={() => manageFilters(data.value, setNm, nm)}>
                                                        <a href="javascript:void(0)">{data.value}</a>
                                                    </li>))}
                                            </ul>*/}
                </div>
              </div>
              <div className="filter_item" id="Ne">
                <div className="title" onClick={() => filter_block('Ne')}>
                  {t('ne')}
                </div>
                <div className="content size">
                  <Multiselect
                    isObject={false}
                    selectedValues={ne} // Preselected value to persist in dropdown
                    onSelect={(selectedList, selectedItem) => {
                      setNe([...selectedList]);
                    }} // Function will trigger on select event
                    onRemove={(selectedList, selectedItem) => {
                      setNe([...selectedList]);
                    }} // Function will trigger on select event
                    options={filters?.ne?.map((data) => {
                      return data.value;
                    })}
                  />

                  {/*<ul className="scroll_fix scroll_fix_with_scroll">
                                                {filters?.ne?.map((data) => (
                                                    <li className={ne.includes(data.value) ? "active" : ""}
                                                        key={data.value}
                                                        onClick={() => manageFilters(data.value, setNe, ne)}>
                                                        <a href="javascript:void(0)">{data.value}</a>
                                                    </li>))}
                                            </ul>*/}
                </div>
              </div>
              <div className="filter_item" id="colore">
                <div className="title" onClick={() => filter_block('colore')}>
                  {t('color')}
                </div>
                <div className="content color">
                  <ul className="scroll_fix">
                    {filters?.colore?.map((data) => (
                      <li
                        className={colore.includes(data.value) ? 'active' : ''}
                        key={data.value}
                        onClick={() => manageFilters(data.value, setColore, colore)}
                      >
                        <a href="#">
                          <div className="item_color">
                            <span className="color" style={{ backgroundColor: data.hex, border: 'none' }} />
                            <span className="color_name">
                              {data.name[0].toUpperCase() + data.name.substring(1).toLowerCase()}
                            </span>
                          </div>
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              <div className="filter_item" id="Utilizzo">
                <div className="title" onClick={() => filter_block('Utilizzo')}>
                  {t('utilizzo')}
                </div>
                <div className="content size">
                  <ul className="scroll_fix">
                    {filters?.utilities?.map((data) => (
                      <li
                        className={utilities.includes(data.value) ? 'active' : ''}
                        key={data.value}
                        onClick={() => manageFilters(data.value, setUtilities, utilities)}
                      >
                        <a href="javascript:void(0)">{data.value}</a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              <div className="filter_item" id="fibra">
                <div className="title" onClick={() => filter_block('fibra')}>
                  {t('fibra')}
                </div>
                <div className="content size">
                  <ul className="scroll_fix">
                    {filters?.fibra?.map((data) => (
                      <li
                        className={fibra.includes(data.value) ? 'active' : ''}
                        key={data.value}
                        onClick={() => manageFilters(data.value, setFibra, fibra)}
                      >
                        <a href="javascript:void(0)">{data.value}</a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="filter_item" id="certificazioni">
                <div className="title" onClick={() => filter_block('certificazioni')}>
                  {t('Certificazioni e impegni')}
                </div>
                <div className="content size">
                  <ul className="scroll_fix">
                    {filters?.certificazioni?.map((data) => (
                      <li
                        className={certificazioni.includes(data.value) ? 'active' : ''}
                        key={data.name}
                        onClick={() => manageFilters(data.value, setCertificazioni, certificazioni)}
                      >
                        <a className="capitalize" href="javascript:void(0)">
                          {data.value}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="filter_item" id="caratteristiche">
                <div className="title" onClick={() => filter_block('caratteristiche')}>
                  {t('caratteristiche')}
                </div>
                <div className="content size">
                  <Multiselect
                    isObject={false}
                    selectedValues={caratteristiche} // Preselected value to persist in dropdown
                    onSelect={(selectedList, selectedItem) => {
                      setCaratteristiche([...selectedList]);
                    }} // Function will trigger on select event
                    onRemove={(selectedList, selectedItem) => {
                      setCaratteristiche([...selectedList]);
                    }} // Function will trigger on select event
                    options={filters?.caratteristiche?.map((data) => {
                      return data.value;
                    })}
                  />

                  {/*<ul className="scroll_fix scroll_fix_with_scroll">

                                                {filters?.caratteristiche?.map((data) => (
                                                    <li className={caratteristiche.includes(data.value) ? "active" : ""}
                                                        key={data.value}
                                                        onClick={() => manageFilters(data.value, setCaratteristiche, caratteristiche)}>
                                                        <a href="javascript:void(0)">{data.value}</a>
                                                    </li>))}
                                            </ul>*/}
                </div>
              </div>
              <div className="filter_item" id="produttive">
                <div className="title" onClick={() => filter_block('produttive')}>
                  {t('tecniche_produttive')}
                </div>
                <div className="content size">
                  <ul className="scroll_fix">
                    {filters?.tecniche_produttive?.map((data) => (
                      <li
                        className={tecniche_produttive.includes(data.value) ? 'active' : ''}
                        key={data.value}
                        onClick={() => manageFilters(data.value, setTecniche_produttive, tecniche_produttive)}
                      >
                        <a href="javascript:void(0)">{data.value}</a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div class="filter_item" id="Cura_del_capo">
                <div class="title" onClick={() => filter_block('Cura_del_capo')}>
                  {t('cura_del_capo')}
                </div>
                <div class="content head_care size">
                  <ul className="scroll_fix ul_modification">
                    {filters?.cura_del_capo?.map((data) => (
                      <>
                        <li
                          className={cura_del_capo.includes(data.value) ? 'active' : ''}
                          key={data.value}
                          onClick={() => manageFilters(data.value, setCura_del_capo, cura_del_capo)}
                        >
                          <div class="item_care">
                            <span class="care_image">
                              <img
                                src={data?.icon_cura_del_capo_full}
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null; // prevents looping
                                  currentTarget.src = '/images/artic_06.jpg';
                                }}
                                alt="img"
                              />
                            </span>
                            <span class="care_name">{data?.value}</span>
                          </div>
                        </li>
                        <div></div>
                      </>
                    ))}
                  </ul>
                </div>
              </div>

              {/*<div className="filter_item" id="Sostenibilità">
                <div className="title" onClick={() => filter_block('Sostenibilità')}>
                  {t('sostenibilita')}
                </div>
                <div className="content size">
                  <ul className="scroll_fix">
                    {filters?.sostenibilita?.map((data) => (
                      <li
                        className={sostenibilita.includes(data.value) ? 'active' : ''}
                        key={data.value}
                        onClick={() => manageFilters(data.value, setSostenibilita, sostenibilita)}
                      >
                        <a href="javascript:void(0)">{data.value}</a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>*/}
            </div>
          )}

          <div className="filter_mobile sidebar">
            <div className="link">
              <a href="#">{t('mostra_99_articoli')}</a>
            </div>
          </div>
        </div>
        {isLoading ? (
          <>
            <div className="loader_wrapper">
              <div class="loader"></div>
            </div>
          </>
        ) : (
          <div className="product_section">
            <div className="product_top">
              <div className="title_section">
                <h2> {t('configura_il_tuo_filato')}</h2>
                {showingSearchResult ? (
                  <h1 className="mobile_search">
                    {t('risulati_di_ricerca_per')}: <b>“{q}”</b>
                  </h1>
                ) : (
                  ''
                )}
              </div>
              {true || showingSearchResult ? (
                ''
              ) : (
                <div className="toolbar_section d-none">
                  <p>{t('visualizza_per')}</p>
                  <div className="sorter_dropdown">
                    <a href="javascript:void(0);" className="sorter_current active">
                      {t('collezione')}
                    </a>
                    <div className="sorter_options">
                      <ul className="scroll_fix">
                        <li>
                          <a href="javascript:void(0);" value={10}>
                            {t('collezione')}
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0);" value={25}>
                            {t('finezza')}
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0);" value={50}>
                            {t('color')}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <InfiniteScroll
              pageStart={0}
              limit={15}
              loadMore={() => fetchMoreItems(page)}
              className="row"
              dataLength={100}
              // initialScrollY={0}
              // pageStart={1}
              // limit={50}
              // threshold={3000}
              hasMore={hasMore}
              loader={
                <>
                  <div className="infinite_loader">
                    <div class="loader"></div>
                  </div>
                </>
              }

              // endMessage={<>Done</>}
            >
              <div className="product_items">
                {hasCustomProducts && !showingSearchResult ? (
                  <Link to={getTranslated(i18n, customProductPaths, { en: 'en', it: 'it' }, 'it')}>
                    <div className="product_item">
                      <img src={CUSTOM_PRODUCT_IMAGE_SRC} alt="Immagine" />
                      <div className="product_data">
                        <span className="tag">{t('Prodotti personalizzati')}</span>
                      </div>
                    </div>
                  </Link>
                ) : null}
                {results?.map((result, idx) => {
                  return <Product_component key={result.id} data={result} />;
                })}
              </div>
            </InfiniteScroll>
          </div>
        )}
      </div>
    </div>
  );
};

export default Category;
