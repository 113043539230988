import React from 'react';
import Slider from 'react-slick';

import Product_component from './product_component';

var settings = {
  dots: false,
  arrows: true,
  infinite: false,
  speed: 500,
  slidesToShow: 3.5,
  slidesToScroll: 1,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2.5,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1.5,
        //   initialSlide: 2
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1.1,
      },
    },
  ],
};
function Suggestedslider({ details }) {
  return (
    <>
      <Slider {...settings}>
        {details?.prodotti_random?.map((data, idx) => (
          <>
            <Product_component key={data.id} data={data} />
          </>
        ))}
      </Slider>
    </>
  );
}

export default Suggestedslider;
