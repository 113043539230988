import { React, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import axios from 'axios';
import swal from 'sweetalert';

import { useModal } from '../../providers/ModalProvider';
import { useLoader } from '../../utils/hooks';

const User_Create = (lodemore) => {
  const [tab, setTab] = useState('Ordini');
  const [userData, setUserData] = useState({
    fname: '',
    lname: '',
    username: '',
    phone: '',
    email: '',
    role: '',
    password: '',
    password_confirmation: '',
    view_catalog: true,
    create_order: true,
    order_history: true,
    download_document: true,
    create_ticket: true,
    ticket_history: true,
  });
  const [editData, setEditData] = useState({});
  const params = useParams();
  const { t } = useTranslation();
  const fetchUserLoader = useLoader({ height: '80vh' });
  const submitUserLoader = useLoader({ paddingBottom: '24px' });

  const dataUser = useSelector((state) => state?.auth?.userDataDetail);

  const getSubUserDetail = () => {
    fetchUserLoader.watchPromise(
      axios
        .get(`v1/view-sub-users/${params?.id}`)
        .then((res) => {
          setUserData({
            fname: res?.data?.result?.data.fname,
            lname: res?.data?.result?.data?.lname,
            username: res?.data?.result?.data?.username,
            phone: res?.data?.result?.data?.phone,
            email: res?.data?.result?.data?.email,
            role: res?.data?.result?.data?.role,
            view_catalog: res?.data?.result?.data?.view_catalog,
            create_order: res?.data?.result?.data?.create_order,
            order_history: res?.data?.result?.data?.order_history,
            download_document: res?.data?.result?.data?.download_document,
            create_ticket: res?.data?.result?.data?.create_ticket,
            ticket_history: res?.data?.result?.data?.ticket_history,
          });
        })
        .catch(function (err) {
          if (err.response) {
            console.log('response', err.response);
            /* swal('valication',err.response.data.message.replace(".,", "\n"), "error"); */
          }
        })
    );
  };

  const handleChange = (e) => {
    const data = { ...userData };
    data[e.target.name] = e.target.value;
    setUserData(data);
  };
  const modal = useModal();

  const handleSubmit = async (event) => {
    event.preventDefault();
    var FormData = require('form-data');
    let data = new FormData();
    data.append('parent_id', dataUser.id);
    data.append('fname', userData?.fname /* : event.target['fname'].value */);
    data.append('lname', userData?.lname /* event.target['lname'].value */);
    data.append('username', userData?.username /* event.target['username'].value */);
    data.append('phone', userData?.phone /* event.target['phone'].value */);
    data.append('email', userData?.email /* event.target['email'].value */);
    data.append('role', userData?.role /* event.target['role'].value */);
    if (userData?.password != undefined) {
      data.append('password', userData?.password /* event.target['password'].value */);
      data.append(
        'password_confirmation',
        userData?.password_confirmation /* event.target['password_confirmation'].value */
      );
    }

    data.append('view_catalog', userData?.view_catalog ? 1 : 0 /* event.target['view_catalog'].value */);
    data.append('create_order', userData?.create_order ? 1 : 0 /* event.target['create_order'].value */);
    data.append('order_history', userData?.order_history ? 1 : 0 /* event.target['order_history'].value */);
    data.append('download_document', userData?.download_document ? 1 : 0 /* event.target['download_document'].value */);
    data.append('create_ticket', userData?.create_ticket ? 1 : 0 /* event.target['create_ticket'].value */);
    data.append('ticket_history', userData?.ticket_history ? 1 : 0 /* event.target['ticket_history'].value */);

    if (params.id) {
      data.append('_method', 'put');
      submitUserLoader.watchPromise(
        axios({
          method: 'post',
          url: `v1/update-sub-users/${params?.id}`,
          data: data,
          headers: {
            'Content-Type': 'multipart/form-data',
            Accept: 'application/json',
          },
        })
          .then(function (response) {
            modal.showSuccess({
              subtitle: t('popup.subuserCreationSuccess'), //response.data.message.replace('.,', '\n'),
              onConfirm: () => {
                window.location.href = '/dashboard-list';
              },
            });
            // swal('Success', response.data.message.replace('.,', '\n'), 'success').then(function () {
            //   window.location.href = '/dashboard-list';
            // });
          })
          .catch(function (err) {
            if (err.response) {
              modal.showAttention({ subtitle: err.response.data.message.replace('.,', '\n') });

              // swal('valication', err.response.data.message.replace('.,', '\n'), 'error');
            }
          })
      );
    } else {
      submitUserLoader.watchPromise(
        axios({
          method: 'post',
          url: 'v1/insert-sub-users',
          data: data,
          headers: {
            'Content-Type': 'multipart/form-data',
            Accept: 'application/json',
          },
        })
          .then(function (response) {
            modal.showSuccess({
              subtitle: t('popup.subuserCreationSuccess'),
              onConfirm: () => {
                window.location.href = '/dashboard-list';
              },
            });
            // swal('Success', response.data.message.replace('.,', '\n'), 'success').then(function () {
            //   window.location.href = '/dashboard-list';
            // });
          })
          .catch(function (err) {
            if (err.response) {
              modal.showAttention({ subtitle: err.response.data.message.replace('.,', '\n') });
              // swal('valication', err.response.data.message.replace('.,', '\n'), 'error');
            }
          })
      );
    }
  };

  useEffect(() => {
    document.getElementById('home_page_class').classList.remove('home_page');
    getSubUserDetail();
  }, [params?.id]);

  return (
    <>
      <div className="main_content utenti_create">
        <div className="utenti_form_section">
          <div className="utenti_form_wrapper container">
            {fetchUserLoader.asJsx || (
              <>
                <div className="utenti_form_title">
                  <h2>{t('Crea nuovo utente')}</h2>
                  <p>{t('Compila i seguenti campi per creare un nuovo utente')}</p>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="info_block user_info">
                    <div className="block_title">
                      <h3>{t('Informazioni utente')}</h3>
                    </div>
                    <div className="form-input user_field">
                      <input
                        type="text"
                        placeholder={t('Nome') + '*'}
                        name="fname"
                        onChange={(e) => handleChange(e)}
                        value={userData?.fname}
                      />
                    </div>
                    <div className="form-input password_field">
                      <input
                        type="text"
                        name="lname"
                        placeholder={t('Cognome') + '*'}
                        onChange={(e) => handleChange(e)}
                        value={userData?.lname}
                      />
                    </div>
                    <div className="form-input user_field">
                      <input
                        type="text"
                        name="email"
                        placeholder={t('Email') + '*'}
                        onChange={(e) => handleChange(e)}
                        value={userData?.email}
                      />
                    </div>
                    <div className="form-input password_field">
                      <input
                        type="text"
                        name="phone"
                        placeholder={t('Telefono') + '*'}
                        onChange={(e) => handleChange(e)}
                        value={userData?.phone}
                      />
                    </div>
                    <div className="form-input user_field">
                      <input
                        type="text"
                        name="role"
                        placeholder={t('Ruolo') + '*'}
                        onChange={(e) => handleChange(e)}
                        value={userData?.role}
                      />
                    </div>
                  </div>
                  <div className="info_block login_info">
                    <div className="block_title">
                      <h3>{t('Dati di accesso')}</h3>
                    </div>
                    <div className="form-input user_field">
                      <input
                        type="text"
                        name="username"
                        placeholder={t('User') + '*'}
                        onChange={(e) => handleChange(e)}
                        value={userData?.username}
                      />
                    </div>
                    <div className="form-input password_field">
                      <input
                        type="password"
                        name="password"
                        placeholder={t('Password') + '*'}
                        onChange={(e) => handleChange(e)}
                        value={userData?.password}
                      />
                      <span className="eye_icon" />
                    </div>
                    <div className="form-input user_field">
                      <input
                        type="password"
                        name="password_confirmation"
                        placeholder={t('Conferma password') + '*'}
                        onChange={(e) => handleChange(e)}
                        value={userData?.password_confirmation}
                      />
                    </div>
                  </div>
                  <div className="info_block privileges_info">
                    <div className="block_title">
                      <h3>{t('Privilegi')}</h3>
                    </div>
                    <div className="block_content">
                      <div className="left_section">
                        <div className="checkbox_section">
                          <h3>{t('Visualizzazione catalogo')}</h3>
                          <div>
                            <input
                              type="radio"
                              id="catalogo_yes"
                              name="view_catalog"
                              checked={userData?.view_catalog}
                              onChange={(e) => setUserData({ ...userData, view_catalog: true })}
                            />
                            <label htmlFor="catalogo_yes">{t('si')}</label>
                          </div>
                          <div>
                            <input
                              type="radio"
                              id="catalogo_no"
                              name="view_catalog"
                              checked={!userData?.view_catalog}
                              onChange={(e) => setUserData({ ...userData, view_catalog: false })}
                            />
                            <label htmlFor="catalogo_yes">{t('NO')}</label>
                          </div>
                        </div>
                        <div className="checkbox_section">
                          <h3>{t('Creazione nuovo ordine')}</h3>
                          <div>
                            <input
                              type="radio"
                              id="ordine_yes"
                              name="create_order"
                              checked={userData?.create_order}
                              onChange={(e) => setUserData({ ...userData, create_order: true })}
                            />
                            <label htmlFor="catalogo_yes">{t('si')}</label>
                          </div>
                          <div>
                            <input
                              type="radio"
                              id="ordine_no"
                              name="create_order"
                              checked={!userData?.create_order}
                              onChange={(e) => setUserData({ ...userData, create_order: false })}
                            />
                            <label htmlFor="catalogo_yes">{t('NO')}</label>
                          </div>
                        </div>
                        <div className="checkbox_section">
                          <h3>{t('Visualizzazione storico ordini')}</h3>
                          <div>
                            <input
                              type="radio"
                              id="storico_yes"
                              name="order_history"
                              checked={userData?.order_history}
                              onChange={(e) => setUserData({ ...userData, order_history: true })}
                            />
                            <label htmlFor="catalogo_yes">{t('si')}</label>
                          </div>
                          <div>
                            <input
                              type="radio"
                              id="storico_no"
                              name="order_history"
                              checked={!userData?.order_history}
                              onChange={(e) => setUserData({ ...userData, order_history: false })}
                            />
                            <label htmlFor="catalogo_yes">{t('NO')}</label>
                          </div>
                        </div>
                      </div>
                      <div className="right_section">
                        <div className="checkbox_section">
                          <h3>{t('Download documenti')}</h3>
                          <div>
                            <input
                              type="radio"
                              id="documenti_yes"
                              name="download_document"
                              checked={userData?.download_document}
                              onChange={(e) =>
                                setUserData({
                                  ...userData,
                                  download_document: true,
                                })
                              }
                            />
                            <label htmlFor="catalogo_yes">{t('si')}</label>
                          </div>
                          <div>
                            <input
                              type="radio"
                              id="documenti_no"
                              name="download_document"
                              checked={!userData?.download_document}
                              onChange={(e) =>
                                setUserData({
                                  ...userData,
                                  download_document: false,
                                })
                              }
                            />
                            <label htmlFor="catalogo_yes">{t('NO')}</label>
                          </div>
                        </div>
                        <div className="checkbox_section">
                          <h3>{t('Creazione nuovo ticket')}</h3>
                          <div>
                            <input
                              type="radio"
                              id="nuovo_yes"
                              name="create_ticket"
                              checked={userData?.create_ticket}
                              onChange={(e) => setUserData({ ...userData, create_ticket: true })}
                            />
                            <label htmlFor="catalogo_yes">{t('si')}</label>
                          </div>
                          <div>
                            <input
                              type="radio"
                              id="nuovo_no"
                              name="create_ticket"
                              checked={!userData?.create_ticket}
                              onChange={(e) => setUserData({ ...userData, create_ticket: false })}
                            />
                            <label htmlFor="catalogo_yes">{t('NO')}</label>
                          </div>
                        </div>
                        <div className="checkbox_section">
                          <h3>{t('Visualizzazione storico ticket')}</h3>
                          <div>
                            <input
                              type="radio"
                              name="ticket_history"
                              id="storico_yes"
                              checked={userData?.ticket_history}
                              onChange={(e) => setUserData({ ...userData, ticket_history: true })}
                            />
                            <label htmlFor="catalogo_yes">{t('si')}</label>
                          </div>
                          <div>
                            <input
                              type="radio"
                              id="storico_no"
                              name="ticket_history"
                              checked={!userData?.ticket_history}
                              onChange={(e) => setUserData({ ...userData, ticket_history: false })}
                            />
                            <label htmlFor="catalogo_yes">{t('NO')}</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="action">
                    {submitUserLoader.asJsx || <button type="submit">{t('Crea utente')}</button>}
                  </div>
                </form>
              </>
            )}
            <div className="create_link">
              <p>
                <Link to="/dashboard-list">{t('Annulla')}</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default User_Create;
