import React from 'react';

import classnames from 'classnames';

import './styles.scss';

const AuthFormWrapper = ({ className, children }) => {
  return <div className={classnames(['auth-form-wrapper', className])}>{children}</div>;
};

export default AuthFormWrapper;
