import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import Loader from '../components/Loader';
import translatablePaths from '../constants/translatablePaths';

export function useLoader(style = {}, initLoading = false, passedDelaySeconds = 0) {
  const timerRef = useRef(null);

  const [loading, setLoading] = useState(initLoading);
  const asJsx = useMemo(() => (loading ? <Loader style={style} /> : null), [loading]);

  const clearTimer = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
  };

  const setStartLoading = (delaySeconds = passedDelaySeconds) => {
    clearTimer();
    if (!delaySeconds) {
      setLoading(true);
    } else {
      timerRef.current = setTimeout(() => setLoading(true), delaySeconds * 1000);
    }
  };

  const setEndLoading = () => {
    clearTimer();
    setLoading(false);
  };

  useEffect(() => {
    return () => clearTimer();
  }, []);

  return {
    loading,
    asJsx,
    setStartLoading,
    setEndLoading,
    watchPromise(promise) {
      setStartLoading();
      promise.finally((_) => {
        setEndLoading();
      });
      // this.set
    },
  };
}

export function useTranslatableSearchParams(paramsMap = {}) {
  let [searchParams] = useSearchParams();

  const translatableSearchParams = {
    get(paramName) {
      let value = paramsMap[paramName] || { en: '', it: '', _isDef: true };

      if (value?._isDef) console.log(`Param (${paramName}) is not found in map: ${JSON.stringify(paramsMap, null, 2)}`);

      const currentLang = localStorage.getItem('lang') || 'it';

      return searchParams.get(value[currentLang]);
    },
  };

  return [
    translatableSearchParams,
    () => {
      throw new Error('NOT IMPLEMENTED SET PARAMS');
    },
  ];
}

export function useTranslateAndReplaceUrlWhenLanguageChanged({ baseLang, translatablePathToRedirect }, paramsMap = {}) {
  let [searchParams] = useSearchParams();
  useEffect(() => {
    const currentLang = localStorage.getItem('lang') || 'it';

    if (currentLang !== baseLang) {
      let query = translateExistingQuery(currentLang);

      window.location.replace(translatablePathToRedirect + (query ? '?' + query : ''));
    }

    function translateExistingQuery(targetLang) {
      return Object.values(paramsMap).reduce((acc, map, index) => {
        let targetParamName = map[targetLang];
        let currentParamName = map[baseLang];
        let value = searchParams.get(currentParamName) || '';

        if (!value) return acc;

        let paramWithValue = targetParamName + '=' + value;
        let lastSymbol = index === paramsMap.length - 1 ? '' : '&';
        return acc + paramWithValue + lastSymbol;
      }, '');
    }

    // function
  }, [baseLang]);
}

export function useTranslatableNavigator(translatableParamsMap = {}) {
  const currentLang = localStorage.getItem('lang') || 'it';

  const navigate = (path) => {
    window.location.href = getPath(path);
  };

  const createLink = (path, params = {}) => {
    let query = createQueryFromParams(params);
    let p = getPath(path);
    return query ? p + '?' + query : p;

    function createQueryFromParams(params) {
      let entries = Object.entries(params);

      return entries.reduce((acc, [param, value], index) => {
        let translatable = translatableParamsMap[param] || { en: '', it: '', _isDef: true };
        if (translatable?._isDef)
          console.log(
            `Param (${param}) is not found in translatable map: ${JSON.stringify(
              translatableParamsMap,
              null,
              2
            )}; --createLink--`
          );

        if (!value) return acc;

        let paramName = translatable[currentLang];
        let paramWithValue = paramName + '=' + value;
        let lastSymbol = index === entries.length - 1 ? '' : '&';
        return acc + paramWithValue + lastSymbol;
      }, '');
    }
  };

  return { navigate, createLink };

  function getPath(path) {
    let p = translatablePaths[path];
    if (!p) throw new Error(`Path (${path}) not found in translatablePaths; useTranslatableNavigator`);
    return p[currentLang];
  }
}
