import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export const useUserPermissions = ({}) => {
  const dataUser = useSelector((state) => state?.auth?.userDataDetail);

  const [userPermissions, setUserPermissions] = useState({
    canViewDashboardTabs: false,
    canAddCustomProductToCart: false,
    canAddNormalProductToCart: false,
  });

  useEffect(() => {
    setUserPermissions((prevState) => {
      if (!dataUser) {
        return prevState;
      }

      return {
        ...prevState,
        canViewDashboardTabs: !dataUser.is_prospect,
        canAddCustomProductToCart: !dataUser.is_prospect,
        canAddNormalProductToCart: !dataUser.is_prospect,
      };
    });
  }, [dataUser]);

  return {
    userPermissions,
  };
};

export default useUserPermissions;
