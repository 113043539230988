import React, { Suspense, createContext, useContext, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Provider, useDispatch } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import axios from 'axios';
import { createStore } from 'redux';

import ForgotPassword from './components/Auth/forgotPassword';
//Auth
import Login from './components/Auth/login';
import ResetPassword from './components/Auth/resetPassword';
// Import custom components
// Layouts
import Layout from './components/Layout';
// import i18n (needs to be bundled ;))
import Profile_manage from './components/dashboard_list/Profile_manage';
import Ticket_Create from './components/dashboard_list/Ticket_Create';
import User_Create from './components/dashboard_list/User_Create';
import User_Dashboards from './components/dashboard_list/User_Dashboards';
import User_Edit from './components/dashboard_list/User_Edit';
import OrderDetail from './components/dashboard_list/orderDetail';
import TicketDetail from './components/dashboard_list/ticketDetail';
//pages
import CMSpages from './components/layouts/home/CMSpages';
import Contact from './components/layouts/home/Contact';
import Home from './components/layouts/home/index';
import OrderPlaced from './components/pages/OrderCompleted/OrderPlaced';
import PageNotFound from './components/pages/PageNotFound';
import Cart from './components/pages/cart';
import Checkout from './components/pages/checkout';
import News from './components/pages/news';
import News_details from './components/pages/news_details';
import Search from './components/pages/search';
import Category from './components/product/category';
import SubscribeToNewsletterModal from './components/shared/SubscribeToNewsletterModal';
import { UPDATE_PRODUCTS_IN_CART_COUNTER } from './constants/ActionTypes';
import paths from './constants/translatablePaths';
import { useGetUserDetails } from './hooks/useGetUserDetails';
import './i18n';
import { supportedLanguages } from './i18n';
import Register from './pages/register';
import ModalProvider from './providers/ModalProvider';
import {
  cartPaths,
  checkoutPaths,
  contactPaths,
  customProductPaths,
  orderPlacedPaths,
  productPaths,
  registerPaths,
  TranslatedSlugProvider,
} from './providers/TranslatedSlugProvider';
import rootReducer from './reducers';
import reportWebVitals from './reportWebVitals';

const Product = React.lazy(() => import('./components/product/product'));
const CustomProduct = React.lazy(() => import('./components/product/custom_product'));

const store = createStore(rootReducer);

const authContext = createContext();

const url_array = {
  /* 'localhost:3000':"https://www.filmar.it/admin/public/api", */
  // 'localhost:3000':"https://filmar.on-demand-app.com/api",
  // 'localhost:3000':"https://azvmfilmarecommerce.westeurope.cloudapp.azure.com/admin/public/api",
  // 'localhost:3000': 'https://www.filmar.it/admin/public/api', // local development https://www.filmar.it/admin/public/api
  'localhost:3000': 'http://admin.xyagencydemo.com/api/', // local development https://www.filmar.it/admin/public/api
  // 'localhost:3000': 'http://filmar.locl/api/', // local development https://www.filmar.it/admin/public/api
  'localhost:3100': 'http://localhost:4000/api', // staging // local development https://www.filmar.it/admin/public/api
  // 'localhost:3000': 'http://admin.xyagencydemo.com/api', // local development https://www.filmar.it/admin/public/api
  '154.56.0.243': 'http://admin.xyagencydemo.com/api', // staging 'https://www.filmar.it/admin/public/api', //staging, version is added later
  'xyagencydemo.com': 'http://admin.xyagencydemo.com/api', //prod
  'http://xyagencydemo.com': 'http://admin.xyagencydemo.com/api', //prod
  'https://xyagencydemo.com': 'http://admin.xyagencydemo.com/api', //prod

  'filmarfe.pilcommunication.com': 'http://filmarbe.pilcommunication.com/api', //prod
  'http://filmarfe.pilcommunication.com': 'http://filmarbe.pilcommunication.com/api', //prod
  'https://filmarfe.pilcommunication.com': 'http://filmarbe.pilcommunication.com/api', //prod

  'azvmfilmarecommerce.westeurope.cloudapp.azure.com':
    'https://azvmfilmarecommerce.westeurope.cloudapp.azure.com/admin/public/api',
  'www.filmar.it': 'https://www.filmar.it/admin/public/api',
  'filmar.it': 'https://filmar.it/admin/public/api',
  'filmarweb.on-demand-app.com': 'https://azvmfilmarecommerce.westeurope.cloudapp.azure.com/admin/public/api',
  // 'filmarweb.on-demand-app.com':"https://filmar.on-demand-app.com/api",
  'stage1.adhc.it': 'https://phpstage1.adhc.it/api', // staging adhoc development
};
axios.defaults.baseURL = url_array[window.location.host];
// console.log(`window.location.host:`, window.location.host);

axios.defaults.headers.common = {
  'Accept-Language': localStorage.getItem('lang') || 'it',
};

function useAuth() {
  return useContext(authContext);
}

const fakeAuth = {
  isAuthenticated: false,
  signin(cb) {
    fakeAuth.isAuthenticated = true;
    setTimeout(cb, 100); // fake async
  },
  signout(cb) {
    fakeAuth.isAuthenticated = false;
    setTimeout(cb, 100);
  },
};

function useProvideAuth() {
  const [user, setUser] = useState(null);

  const signin = (cb) => {
    return fakeAuth.signin(() => {
      setUser('user');
      cb();
    });
  };

  const signout = (cb) => {
    return fakeAuth.signout(() => {
      setUser(null);
      cb();
    });
  };

  return {
    user,
    signin,
    signout,
  };
}

function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  const dispatch = useDispatch();
  const { productsInCartCounter } = useGetUserDetails();

  useEffect(() => {
    dispatch({
      type: UPDATE_PRODUCTS_IN_CART_COUNTER,
      productsInCartCounter: productsInCartCounter,
    });
  }, [productsInCartCounter]);

  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

function ProtectedPage() {
  return <h3>Protected</h3>;
}

const ProtectedRoute = ({ children }) => {
  const { user } = useAuth();

  if (!user) {
    // user is not authenticated
    return <Navigate to="/" />;
  }
  return children;
};

ReactDOM.render(
  <Suspense fallback={<>Loading...</>}>
    <Provider store={store}>
      <BrowserRouter basename={'/'}>
        <ModalProvider>
          <ProvideAuth>
            <TranslatedSlugProvider>
              <SubscribeToNewsletterModal />
              <Layout>
                <Routes>
                  <Route path="/" element={<Home className="home_page" />} />
                  <Route path="/login" element={<Login className="a" />} />
                  <Route path="/forgot-password" element={<ForgotPassword />} />
                  <Route path="/reset-password" element={<ResetPassword />} />
                  {Object.entries(registerPaths).map(([lang, path]) => (
                    <Route path={path} element={<Register className="a" />} />
                  ))}
                  {Object.entries(contactPaths).map(([lang, path]) => (
                    <Route path={path} element={<Contact className="a" />} />
                  ))}
                  {Object.entries(productPaths).map(([lang, path]) => (
                    <Route exact path={path} element={<Category className="a" />} />
                  ))}
                  {supportedLanguages.map((lang) => (
                    <>
                      <Route exact path={`/${lang}/news`} element={<News className="a" />} />
                      <Route exact path={`/${lang}/news/:slug`} element={<News_details className="a" />} />
                      <Route exact path={`/${lang}/page/:slug`} element={<CMSpages className="a" />} />
                    </>
                  ))}
                  <Route exact path="/search" element={<Search className="a" />} />
                  <Route exact path="/dashboard-list" element={<User_Dashboards className="a" />} />
                  <Route exact path="/order-detail/:id/:status" element={<OrderDetail className="a" />} />
                  <Route exact path="/user-create" element={<User_Create className="a" />} />
                  <Route exact path="/user-edit/:id" element={<User_Edit className="a" />} />
                  <Route exact path="/profile-manage/:id" element={<Profile_manage className="a" />} />
                  {Object.entries(cartPaths).map(([lang, path]) => (
                    <Route exact path={path} element={<Cart className="a" />} />
                  ))}
                  {Object.entries(checkoutPaths).map(([lang, path]) => (
                    <Route exact path={path} element={<Checkout className="a" />} />
                  ))}
                  {Object.entries(orderPlacedPaths).map(([lang, path]) => (
                    <Route exact path={path} element={<OrderPlaced />} />
                  ))}
                  <Route
                    exact
                    path="/ticket-create/:customer_id/:order_id?"
                    element={<Ticket_Create className="a" />}
                  />
                  <Route exact path="/ticket-detail/:id/:id_ticket/:status" element={<TicketDetail className="a" />} />
                  <Route
                    path="/protected"
                    element={
                      <ProtectedRoute>
                        <ProtectedPage />
                      </ProtectedRoute>
                    }
                  />
                  {Object.entries(customProductPaths).map(([lang, path]) => (
                    <Route exact path={path} element={<CustomProduct className="a" baseLang="en" />} />
                  ))}
                  {supportedLanguages.map((lang) => (
                    <>
                      <Route
                        exact
                        path={`/${lang}/:family/:productId`}
                        element={<Product className="a" baseLang="en" />}
                      />
                    </>
                  ))}
                  <Route path="*" element={<PageNotFound />} />
                </Routes>
              </Layout>
            </TranslatedSlugProvider>
          </ProvideAuth>
        </ModalProvider>
      </BrowserRouter>
    </Provider>
  </Suspense>,
  document.getElementById('root')
);

// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
