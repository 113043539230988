import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import useUserPermissions from '../../../hooks/getUserPermissions';
import { registerPaths } from '../../../providers/TranslatedSlugProvider';
import { getTranslated } from '../../../utils/translations';
import PrimaryButton from '../../Buttons/PrimaryButton/PrimaryButton';
import SecondaryButton from '../../Buttons/SecondaryButton/SecondaryButton';
import Colorpicker from '../Colorpicker';

import Colorshow from './Colorshow';

export default function ProductPanel({
  setShowRequestSample,
  setTab,
  details,
  tab,
  screen,
  handleAccordation,
  subProduct,
  isLoadingFilter,
  setActivedetails,
  openMiniCart,
  colorname,
  colorDisponibili,
  setFilterColor,
  colorList,
  filterColor,
  handleColorSelect,
  activeColor,
  cID,
  userDataDetail,
  setSubProduct,
  activedetails,
}) {
  const { t, i18n } = useTranslation();
  const { userPermissions } = useUserPermissions({});
  const agentDataDetail = useSelector((state) => state?.auth?.agentDataDetail);

  return (
    <div className="product_tab">
      <span className={`tab_title ${tab == 0 && 'active'}`} onClick={() => setTab(0)}>
        {t('configura_prodotto')}
      </span>
      {(tab == 0 || screen.width <= 600) && (
        <div className="tab_content configure">
          <div className="accordion_info">
            <div className="accordion_title" id="test_1" onClick={() => handleAccordation('accordation_1', 'test_1')}>
              {subProduct ? (
                <p className="p_details_">
                  {t('articolo')}:{' '}
                  <b>
                    {subProduct?.nome_articolo}
                    {subProduct?.is_mel == 'M' ? ' mèlange' : ''} | {subProduct?.composition} | {subProduct?.nm} |{' '}
                    {subProduct?.ne} | {subProduct?.finezza || 'NA'} | {subProduct?.servizio || 'NA'}{' '}
                  </b>
                </p>
              ) : (
                <h3>{t('seleziona_articolo')}</h3>
              )}

              <span>
                {details?.sub_produts?.length} {t('articoli_disponibili')}
              </span>
            </div>
            <div className="accordion_content" id="accordation_1">
              <div className="select_section custom-scrollbar">
                <div className="select_header " id="accordation_12">
                  <div className="select_row">
                    <div className="select_title name">{t('Nome')}</div>
                    <div className="select_title composition">{t('composizione')}</div>
                    <div className="select_title">{t('nm')}</div>
                    <div className="select_title">{t('ne')}</div>
                    <div className="select_title finesse">{t('finezza')}</div>
                    <div className="select_title finesse">{t('servizio')}</div>
                  </div>
                </div>
                <div className="select_body header_stiky_1">
                  {details?.sub_produts?.map((singleProduct, idx) => (
                    <div
                      key={singleProduct?.configid + idx}
                      onClick={() => {
                        setSubProduct(singleProduct);
                        handleAccordation('accordation_2', 'test_2', 'open'),
                          handleAccordation('accordation_1', 'test_1');
                      }}
                      className={`select_row ${singleProduct?.id === subProduct?.id && 'active_selecteed_color'}`}
                    >
                      <div className="select_column name" data-label="Nome">
                        {singleProduct?.nome_articolo} {singleProduct?.is_mel == 'M' ? ' mèlange' : ''}
                      </div>
                      <div className="select_column composition" data-label="Composizione">
                        {getTranslated(
                          i18n,
                          singleProduct,
                          { en: 'productname_en', it: 'productname_it ' },
                          'productname_it'
                        )}
                      </div>

                      <div className="select_column" data-label="Nm">
                        {singleProduct?.nm}
                      </div>
                      <div className="select_column" data-label="Ne">
                        {singleProduct?.ne}
                      </div>
                      <div className="select_column finesse" data-label="Fin">
                        {singleProduct?.finezza || 'NA'}
                      </div>
                      <div className="select_column finesse" data-label="Fin">
                        {singleProduct?.servizio || 'NA'}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="accordion_info colore" id="accordion_info_color">
            <div className="accordion_title " id="test_2" onClick={() => handleAccordation('accordation_2', 'test_2')}>
              <h3>
                {' '}
                {colorname ? (
                  <>
                    {t('color')}: {colorname}
                  </>
                ) : (
                  t('seleziona_colore')
                )}
              </h3>

              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span className="disponibili_span">
                  {subProduct ? subProduct?.colori_in_cartella + ' ' + t('colori_disponibili') : ''}
                </span>
                <div className="more_color" style={{ display: 'none' }}>
                  <div className="more_img">
                    <img onClick={colorDisponibili} src="/images/product/color_expand.png" alt="color_expand" />
                    <Colorpicker setFilterColor={setFilterColor} color={colorList} />
                  </div>
                </div>
              </div>
            </div>
            <p>{t('immagini')}</p>

            <div className="accordion_content" id="accordation_2">
              {!isLoadingFilter ? (
                <>
                  <Colorshow
                    filterColor={filterColor}
                    colorList={colorList}
                    handleColorSelect={handleColorSelect}
                    activeColor={activeColor}
                    cid={cID}
                  />{' '}
                </>
              ) : (
                <div className="filter_color_loader">
                  <div class="loader"></div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <span className={` tab_title ${tab == 1 && 'active'}`} onClick={() => setTab(1)}>
        {t('dettagli')}
      </span>
      {(tab == 1 || screen.width <= 600) && (
        <div className="tab_content details">
          <div className="accordion_info">
            {/* <div 
                                className={activedetails == 1 ? "accordion_title accordion_title_copy active_accordation" : "accordion_title accordion_title_copy "}
                                onClick={() => setActivedetails(1)}>
                                <h3>{t("Descrizione")}</h3>
                            </div> */}
            <div className="accordion_content" style={{ maxHeight: activedetails == 1 ? '100%' : 0 }}>
              <p>{details?.description_it}</p>
              {/*<div className="content_link">*/}
              {/*  <a href="#">{t("scopri_di_piu")}</a>*/}
              {/*</div>*/}
            </div>
          </div>
          <div className="accordion_info">
            <div
              className={
                activedetails == 2
                  ? 'accordion_title accordion_title_copy active_accordation'
                  : 'accordion_title accordion_title_copy '
              }
              onClick={() => setActivedetails(2)}
            >
              <h3>{t('caratteristiche')}</h3>
            </div>
            <div className="accordion_content" style={{ maxHeight: activedetails == 2 ? '100%' : 0 }}>
              <ul className="characteristics">
                {details?.FilterDetail?.map((id, index) => {
                  return id.type == 'caratteristiche' ? (
                    <li key={index}>
                      <img className="characteristics_icon_2" src={id?.detail?.icon_url} alt="" />
                    </li>
                  ) : (
                    ''
                  );
                })}
              </ul>
              <div className="content_link">
                <a href="#">{t('scopri_di_piu')}</a>
              </div>
            </div>
          </div>
          <div className="accordion_info">
            <div
              className={
                activedetails == 3
                  ? 'accordion_title accordion_title_copy active_accordation'
                  : 'accordion_title accordion_title_copy '
              }
              onClick={() => setActivedetails(3)}
            >
              <h3>{t('certificazioni')}</h3>
            </div>
            <div className="accordion_content" style={{ maxHeight: activedetails == 3 ? '100%' : 0 }}>
              <ul>
                {details?.FilterDetail?.map((id, index) => {
                  return id.type == 'certificazioni' ? (
                    <li key={index}>
                      <img className="characteristics_icon_2" src={id?.detail?.icon_url} alt="" />
                    </li>
                  ) : (
                    ''
                  );
                })}
              </ul>
              <div className="content_link">
                <a href="#">{t('scopri_di_piu')}</a>
              </div>
            </div>
          </div>
          <div className="accordion_info">
            <div
              className={
                activedetails == 4
                  ? 'accordion_title accordion_title_copy active_accordation'
                  : 'accordion_title accordion_title_copy '
              }
              onClick={() => setActivedetails(4)}
            >
              <h3>{t('cura_del_prodotto')}</h3>
            </div>
            <div className="accordion_content" style={{ maxHeight: activedetails == 4 ? '100%' : 0 }}>
              <ul>
                {details?.FilterDetail?.map((id, index) => {
                  return id.type == 'cura_del_prodotto' ? (
                    <li key={index}>
                      <img className="characteristics_icon_2" src={id?.detail?.icon_url} alt="" />
                    </li>
                  ) : (
                    ''
                  );
                })}
              </ul>
              <p className="tag_line">{t('i_colori_non_sono_solidi_alla')}</p>
              <div className="content_link">
                <a href="#">{t('scopri_di_piu')}</a>
              </div>
            </div>
          </div>
        </div>
      )}

      <span className={`tab_title ${tab == 3 && 'active'}`} onClick={() => setTab(3)}>
        {t('Cartella colore')}
      </span>
      {(tab == 3 || screen.width <= 600) && (
        <div className="tab_content details">
          <div className="accordion_info">
            <div className="links new_link">
              <a target="_blank" href={'https://filmar.it/media/2023_book_ss24_digitale_web.pdf'}>
                {t('General book 2025')}
              </a>
            </div>
            <div className="links new_link">
              <a target="_blank" href={'https://www.filmar.it/media/2023_book_aw_24-25_digital_web.pdf'}>
                {t('Autumn - Winter 2024-2025')}
              </a>
            </div>
            <div className="links new_link">
              {/* <a href="#">{t("scarica_catalogo")}</a> */}
              {details?.p_url ? (
                <a target="_blank" href={details?.p_url || '#'}>
                  {t('Cartella colore')}
                </a>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      )}

      {['COTTON STORE', 'FILOSCOZIA'].some((name) => details?.p_name?.includes(name)) ? (
        <>
          <span className={`tab_title ${tab == 2 && 'active'}`} onClick={() => setTab(2)}>
            {t('Digital Product Passport')}
          </span>
          {(tab == 2 || screen.width <= 600) && (
            <div className="tab_content digital_product_passport">
              {['COTTON STORE'].some((name) => details?.p_name?.includes(name)) ? (
                <>
                  <div className="links new_link">
                    <a target="_blank" href="https://app.sustainablebrandplatform.com/Combed.html">
                      COTTONSTORE - 30/1 COLORI NORMALI - Combed Melange
                    </a>
                  </div>
                  <div className="links new_link">
                    <a target="_blank" href="https://app.sustainablebrandplatform.com/Dyed_Yarn.html">
                      COTTONSTORE - 30/1 COLORI MELANGE
                    </a>
                  </div>
                </>
              ) : null}
              {['FILOSCOZIA'].some((name) => details?.p_name?.includes(name)) ? (
                <>
                  <div className="links new_link">
                    <a target="_blank" href="https://app.sustainablebrandplatform.com/Mercerized.html ">
                      FILOSCOZIA 60/2 - Mercerized
                    </a>
                  </div>
                </>
              ) : null}
            </div>
          )}
        </>
      ) : (
        ''
      )}

      <div className="order_action mobile_section">
        {userPermissions.canAddNormalProductToCart ? <p>{t('per_ordinire_il_seguente')}</p> : null}
        {userDataDetail?.id && !(agentDataDetail && agentDataDetail?.id === userDataDetail?.id) ? (
          <PrimaryButton fullWidth onClick={() => setShowRequestSample(true)}>
            {t('richiedi_campionatura')}
          </PrimaryButton>
        ) : (
          <Link to={getTranslated(i18n, registerPaths, { en: 'en', it: 'it' }, 'it')}>
            {t('richiedi_campionatura')}
          </Link>
        )}
        <div className="login_action">
          {userDataDetail?.id == undefined ? (
            <Link to={getTranslated(i18n, registerPaths, { en: 'en', it: 'it' }, 'it')}>
              {t('Aggiungi_al_carrello')}
            </Link>
          ) : userPermissions.canAddNormalProductToCart ? (
            agentDataDetail && agentDataDetail?.id === userDataDetail?.id ? (
              <PrimaryButton fullWidth>{t('agent.select-client')}</PrimaryButton>
            ) : (
              <PrimaryButton fullWidth onClick={openMiniCart}>
                {t('Aggiungi_al_carrello')}
              </PrimaryButton>
            )
          ) : null}
        </div>
      </div>
    </div>
  );
}
