import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import axios from 'axios';

export const CC_BOOK_PE_2025 = 'CC BOOK PE2025';
export const CC_BOOK_AI_2024 = 'CC BOOK AI2024';
export const TELLO_ID = 'tello';
export const FONDO_CONO_ID = 'fondo_cono';
export const SAMPLE_PRODUCT_TYPE = 'sample';

export const useSampleProducts = ({ configid, productsForFondoCono }) => {
  const { t, i18n } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [sampleProducts, setSampleProducts] = useState([]);

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    const fetchData = async () => {
      setIsLoading(true);
      const sProducts = [
        {
          codice_articolo: CC_BOOK_PE_2025,
          title: 'BOOK PE 2025',
          desc: null,
          tooltipDesc: t('cartella_colori_description_1'),
          qty: 1,
        },
        {
          codice_articolo: CC_BOOK_AI_2024,
          title: 'BOOK AI 2025',
          desc: null,
          tooltipDesc: t('cartella_colori_description_2'),
          qty: 1,
        },
      ];

      try {
        const response = await axios.post(
          '/v1/sample-products',
          {
            configid,
          },
          { signal }
        );

        if (response?.data?.cartella?.[0]?.codice_articolo) {
          sProducts.push({
            codice_articolo: response?.data?.cartella?.[0]?.codice_articolo,
            title: 'CARTELLA COLORI',
            desc: null,
            tooltipDesc: t('cartella_colori_articolo_description'),
            qty: 1,
          });
        }

        if (response?.data?.tello?.length) {
          sProducts.push({
            codice_articolo: TELLO_ID,
            title: 'Telo',
            desc: t('telo_short_description'),
            tooltipDesc: t('telo_description'),
            subProducts: response?.data?.tello,
            qty: 1,
          });
        }
      } catch (error) {
        console.error(error);
      }

      sProducts.push({
        codice_articolo: FONDO_CONO_ID,
        title: 'FONDO CONO',
        desc: t('fondo_cono_short_description'),
        tooltipDesc: t('fondo_cono_description'),
        subProducts: productsForFondoCono.filter((p) => p.active_fondo_cono),
        qty: 0.3,
      });

      setSampleProducts(sProducts);
      setIsLoading(false);
    };

    fetchData();

    return () => {
      abortController.abort();
    };
  }, []);

  return {
    isLoading,
    sampleProducts,
  };
};
