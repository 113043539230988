import {
  FETCH_SINGLE_PRODUCT,
  CHANGE_CURRENCY,
  RECEIVE_PRODUCTS,
  SET_PRODUCT_DETAIL,
  GET_PRODUCT_DETAIL,
  SET_PRODUCT_DETAIL_ERROR,
  GET_DASHBOARD_PRODUCTS,
  SET_DASHBOARD_PRODUCTS,
  SET_DASHBOARD_PRODUCTS_ERROR,
  GET_DASHBOARD_PRODUCTS_FILTER,
  SET_DASHBOARD_PRODUCTS_FILTER,
  SET_DASHBOARD_PRODUCTS_FILTER_ERROR,
  RESET_DASHBOARD_PRODUCTS,
  SET_BRAND_LIST_NAME,
  SET_PRODUCT_LIST_NAME,
} from '../constants/ActionTypes';

const initialState = {
  products: [],
  symbol: '₹',
  // symbol: "$",
  product_details: [],
  singleProduct: {},
  fetchSingleProductLoader: true,
  isSingleProductFound: true,
  dashboardProducts: [],
  __list_name: '',
  __barnd_name: '',
  fetchDashboardProductLoader: true,
  dashboardProductFilters: [],
  fetchDashboardProductFilterLoader: true,
};

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_PRODUCTS:
      return {
        ...state,
        products: action.products,
      };
    case FETCH_SINGLE_PRODUCT:
      if (state.products.findIndex((product) => product.id === action.productId) !== -1) {
        const singleItem = state.products.reduce((itemAcc, product) => {
          return product;
        }, []);
        return { ...state, product_details: singleItem };
      }
    case CHANGE_CURRENCY:
      return { ...state, symbol: action.symbol };
    case GET_PRODUCT_DETAIL:
      return { ...state, fetchSingleProductLoader: true };
    case SET_PRODUCT_DETAIL_ERROR:
      return {
        ...state,
        singleProduct: {},
        fetchSingleProductLoader: false,
        isSingleProductFound: false,
      };
    case SET_PRODUCT_DETAIL:
      return {
        ...state,
        singleProduct: action.singleProduct,
        fetchSingleProductLoader: false,
        isSingleProductFound: true,
      };
    case GET_DASHBOARD_PRODUCTS:
      return { ...state, fetchDashboardProductLoader: true };
    case SET_DASHBOARD_PRODUCTS_ERROR:
      return {
        ...state,
        dashboardProducts: [],
        fetchDashboardProductLoader: false,
      };
    case SET_DASHBOARD_PRODUCTS:
      return {
        ...state,
        dashboardProducts: [...state.dashboardProducts, ...action.dashboardProducts],
        fetchDashboardProductLoader: action.dashboardProducts.length > 0 ? false : 'noData',
      };
    case GET_DASHBOARD_PRODUCTS_FILTER:
      return { ...state, fetchDashboardProductFilterLoader: true };
    case SET_DASHBOARD_PRODUCTS_FILTER_ERROR:
      return {
        ...state,
        dashboardProductFilters: [],
        fetchDashboardProductFilterLoader: false,
      };
    case SET_DASHBOARD_PRODUCTS_FILTER:
      return {
        ...state,
        dashboardProductFilters: action.dashboardProductFilters,
        fetchDashboardProductFilterLoader: false,
      };
    case RESET_DASHBOARD_PRODUCTS:
      return {
        ...state,
        dashboardProducts: [],
        fetchDashboardProductLoader: true,
      };

    case SET_PRODUCT_LIST_NAME: {
      return {
        ...state,
        __list_name: action.value,
      };
    }
    case SET_BRAND_LIST_NAME:
      return {
        ...state,
        __barnd_name: action.value,
      };
    default:
      return state;
  }
};
export default productReducer;
