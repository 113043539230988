import React, { useMemo } from 'react';

import { CC_BOOK_AI_2024, CC_BOOK_PE_2025, FONDO_CONO_ID, TELLO_ID } from '../../../hooks/useSampleProducts';

const SampleImage = ({ sampleCodiceArticolo, configid, className }) => {
  const handleOnError = ({ currentTarget }) => {
    currentTarget.onerror = null; // prevents looping
    currentTarget.src = '/images/artic_06.jpg';
  };

  const imageSrc = useMemo(() => {
    if (sampleCodiceArticolo === CC_BOOK_PE_2025) {
      return '/images/samples/BOOK_ss_2025.jpg';
    } else if (sampleCodiceArticolo === CC_BOOK_AI_2024) {
      return '/images/samples/BOOK_aw_24-25.jpg';
    } else if (sampleCodiceArticolo === TELLO_ID) {
      return '/images/samples/TELO.jpg';
    } else if (sampleCodiceArticolo === FONDO_CONO_ID) {
      return '/images/samples/FONDO_CONO.jpg';
    } else {
      return `/images/samples/families/${configid}.png`;
    }
  }, [configid, sampleCodiceArticolo]);

  return <img className={className} src={imageSrc} onError={handleOnError} alt="" />;
};

export default SampleImage;
