import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import axios from 'axios';

import { useModal } from '../../providers/ModalProvider';
import { useLoader } from '../../utils/hooks';

const ForgotPassword = () => {
  const { t, i18n } = useTranslation();
  const loader = useLoader({ paddingBottom: '24px' });
  const modal = useModal();

  const handleSubmit = async (event) => {
    event.preventDefault();
    loader.setStartLoading();

    loader.watchPromise(
      axios
        .post('/v1/password-email', {
          email: event.target['email'].value,
        })
        .then(function (response) {
          modal.showSuccess({
            subtitle: t('reset_password_email_sent'),
          });
        })
        .catch(function (error) {
          modal.showAttention({ subtitle: error?.response?.data?.message || t('oops_something_wrong') });
        })
    );
  };

  return (
    <div className="main_content account_login">
      <div className="login_section">
        <div className="login_wrapper container">
          <div className="login_title">
            <h2>{t('Forgot Password')}</h2>
            <p>
              {t(
                "Enter the email address associated with your account and we'll send you a link to reset your password."
              )}
            </p>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="form-input user_field">
              <input required type="text" name="email" placeholder={t('common.username_required')} />
            </div>
            <div className="action">{loader.asJsx || <button type="submit">{t('Continue')}</button>}</div>
          </form>
          <div className="create_link">
            <p>
              <Link to="/login">{t('Back to Login')}</Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
