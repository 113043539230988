import React from 'react';
import { useTranslation } from 'react-i18next';

import { getTranslated } from '../../../utils/translations';
import SelectInput from '../../Inputs/SelectInput';

const RegistrationDetails = ({ className, formDetails, formData, handleFormDataChange, errors }) => {
  const { t, i18n } = useTranslation();

  return (
    <div className={className}>
      <SelectInput
        id="role_in_the_company"
        name="role_in_the_company"
        label={t('register.role-in-the-company')}
        value={formData.role_in_the_company}
        updateValue={(value) => handleFormDataChange('role_in_the_company', value)}
        options={(formDetails?.role_in_the_company || []).map((r) => ({
          value: r.key,
          label: getTranslated(i18n, r, { en: 'name_en', it: 'name_it ' }, 'name_it'),
        }))}
        errors={errors?.role_in_the_company}
      />
      <SelectInput
        id="customer_type"
        name="customer_type"
        label={t('register.customer-type')}
        value={formData.customer_type}
        updateValue={(value) => handleFormDataChange('customer_type', value)}
        options={(formDetails?.customer_type || []).map((r) => ({
          value: r.key,
          label: getTranslated(i18n, r, { en: 'name_en', it: 'name_it ' }, 'name_it'),
        }))}
        errors={errors?.customer_type}
      />
      <SelectInput
        id="production_type"
        name="production_type"
        label={t('register.production-type')}
        value={formData.production_type}
        updateValue={(value) => handleFormDataChange('production_type', value)}
        options={(formDetails?.production_type || []).map((r) => ({
          value: r.key,
          label: getTranslated(i18n, r, { en: 'name_en', it: 'name_it ' }, 'name_it'),
        }))}
        errors={errors?.production_type}
      />
    </div>
  );
};

export default RegistrationDetails;
