import { useEffect, useState } from 'react';

import axios from 'axios';

const API_PRODUCT_DETAIL_ENDPOINT = '/v1/product_detail';

export const useProduct = ({ id, slug }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [mainProduct, setMainProduct] = useState(null);
  const [subProduct, setSubProduct] = useState(null);
  const [isLoadingFilter, setIsLoadingFilter] = useState(false);
  const [colors, setColors] = useState([]);

  // Main Product Details
  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    setIsLoading(true);
    axios
      .post(
        API_PRODUCT_DETAIL_ENDPOINT,
        {
          id: parseInt(id),
          slug: slug,
        },
        { signal }
      )
      .then((response) => {
        setMainProduct(response?.data?.result?.data || null);
      })
      .catch((error) => {
        console.error('Error:', error.message, error);
      })
      .finally(() => {
        setIsLoading(false);
        window.scrollTo(0, 0);
      });

    return () => {
      abortController.abort();
    };
  }, [id, slug]);

  // Sub Product Details
  useEffect(() => {
    if (!subProduct) {
      return;
    }

    const abortController = new AbortController();
    const signal = abortController.signal;

    setIsLoadingFilter(true);
    axios
      .post(
        API_PRODUCT_DETAIL_ENDPOINT,
        {
          id: subProduct?.id,
          slug: subProduct?.slug,
        },
        { signal }
      )
      .then((response) => {
        setMainProduct(response?.data?.result?.data || null);
        setColors(response?.data?.result?.data?.colori || []);
      })
      .catch((error) => {
        console.error('Error:', error.message, error);
      })
      .finally(() => {
        setIsLoadingFilter(false);
      });

    return () => {
      abortController.abort();
    };
  }, [subProduct, slug]);

  return {
    isLoading,
    mainProduct,
    setMainProduct,
    subProduct,
    setSubProduct,
    isLoadingFilter,
    colors,
  };
};
