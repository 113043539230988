import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import axios from 'axios';

export const useCustomProductConfigurations = ({ customProduct }) => {
  const dataUser = useSelector((state) => state?.auth?.userDataDetail);

  const [isLoading, setIsLoading] = useState(false);
  const [availableColors, setAvailableColors] = useState([]);

  useEffect(() => {
    setAvailableColors([]);

    if (!customProduct) {
      return;
    }

    const abortController = new AbortController();
    const signal = abortController.signal;

    setIsLoading(true);

    axios
      .post(
        '/v1/custom-product-colors',
        {
          item_id: customProduct.item_id,
        },
        {
          headers: {
            Authorization: `Bearer ${dataUser.token}`,
          },
          signal,
        }
      )
      .then((res) => {
        setAvailableColors(res?.data?.result?.data || []);
      })
      .catch((error) => {
        console.error('error', error);
      })
      .finally(() => setIsLoading(false));

    return () => abortController.abort();
  }, [customProduct]);

  return {
    isLoading,
    availableColors,
  };
};
