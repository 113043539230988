import React, { useState } from 'react';

import TextInput from '../TextInput';
import ValidationError from '../ValidationError';

import './style.scss';

const SelectInput = ({ id, name, label, value, updateValue, options, errors = [] }) => {
  const OTHER_VALUE_TRIGGER = 'specify';

  const [isOtherValue, setIsOtherValue] = useState(false);

  const handleSelectChange = (e) => {
    setIsOtherValue(e.target.value === OTHER_VALUE_TRIGGER);
    updateValue(e.target.value === OTHER_VALUE_TRIGGER ? '' : e.target.value);
  };

  return (
    <div className="select-input">
      {label ? <label htmlFor={id}>{label}</label> : null}
      <select id={id} value={isOtherValue ? OTHER_VALUE_TRIGGER : value} onChange={handleSelectChange}>
        {options.map((o) => (
          <option value={o.value}>{o.label}</option>
        ))}
      </select>
      {isOtherValue ? (
        <TextInput
          id={`${id}-other`}
          name={`${name}-other`}
          value={value}
          updateValue={(value) => updateValue(value)}
        />
      ) : null}
      {errors.length ? <ValidationError errorMessage={errors[0]} /> : null}
    </div>
  );
};

export default SelectInput;
