import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import axios from 'axios';
import { useDebounce } from 'use-debounce';

export const useUserOrderList = ({}) => {
  const dataUser = useSelector((state) => state?.auth?.userDataDetail);

  const [perPage, setPerPage] = useState(10);

  const filtersInitialState = {
    salesId: '',
    status: '',
    startDate: '',
    endDate: '',
  };
  const [filters, setFilters] = useState(filtersInitialState);

  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const debouncedFilters = useDebounce(filters, 300);

  const resetFilter = () => {
    setFilters(filtersInitialState);
  };

  const updateFilter = (filter, value) => {
    setFilters((prevState) => ({
      ...prevState,
      [filter]: value,
    }));
  };

  useEffect(() => {
    const { salesId, status, startDate, endDate } = debouncedFilters;
    const abortController = new AbortController();
    const signal = abortController.signal;

    setIsLoading(true);

    axios
      .post(
        '/v1/order-by-client-with-filter',
        {
          customerid: dataUser.ACCOUNTNUM,
          salesDateStart: startDate,
          salesDateEnd: endDate,
          salesStatus: status,
          salesId: salesId,
          fetch: perPage,
          offset: (page - 1) * perPage,
        },
        {
          headers: {
            Authorization: `Bearer ${dataUser?.token}`,
          },
          signal,
        }
      )
      .then((res) => {
        setRows(res?.data?.result?.data?.payload?.data || []);
        setTotalPages(1000);
      })
      .catch((error) => {
        console.error('error', error);
        setRows([]);
        setTotalPages(0);
      })
      .finally(() => setIsLoading(false));

    return () => abortController.abort();
  }, [debouncedFilters, perPage, page]);

  return {
    isLoading,
    rows,
    page,
    setPage,
    filters,
    updateFilter,
    totalPages,
    perPage,
    setPerPage,
    resetFilter,
  };
};
