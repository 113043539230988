export const GET_HOME_BRANDS = 'GET_HOME_BRANDS';
export const SET_HOME_BRANDS = 'SET_HOME_BRANDS';
export const GET_HOME_FOOTER_CATEGORY = 'GET_HOME_FOOTER_CATEGORY';
export const SET_HOME_FOOTER_CATEGORY = 'SET_HOME_FOOTER_CATEGORY';
export const GET_HOME_MAIN_MENU = 'GET_HOME_MAIN_MENU';
export const SET_HOME_MAIN_MENU = 'SET_HOME_MAIN_MENU';
export const GET_HOME_SLIDER = 'GET_HOME_SLIDER';
export const SET_HOME_SLIDER = 'SET_HOME_SLIDER';
export const GET_HOME_SIDEBAR_CATEGORIES = 'GET_HOME_SIDEBAR_CATEGORIES';
export const SET_HOME_SIDEBAR_CATEGORIES = 'SET_HOME_SIDEBAR_CATEGORIES';
export const GET_HOME_DASHBOARD_MODULES = 'GET_HOME_DASHBOARD_MODULES';
export const SET_HOME_DASHBOARD_MODULES = 'SET_HOME_DASHBOARD_MODULES';
export const GET_HOME_DASHBOARD_MODULES_NEW = 'GET_HOME_DASHBOARD_MODULES_NEW';
export const SET_HOME_DASHBOARD_MODULES_NEW = 'GET_HOME_DASHBOARD_MODULES_NEW';

// Get Products
export const GET_PRODUCT_HOME_PAGE_PRODUCT = 'GET_PRODUCT_HOME_PAGE_PRODUCT';
export const SET_PRODUCT_HOME_PAGE_PRODUCT = 'SET_PRODUCT_HOME_PAGE_PRODUCT';
export const GET_PRODUCT_DETAIL = 'GET_PRODUCT_DETAIL';
export const SET_PRODUCT_DETAIL = 'SET_PRODUCT_DETAIL';
export const SET_PRODUCT_DETAIL_ERROR = 'SET_PRODUCT_DETAIL_ERROR';

// Dashboad Products
export const RESET_DASHBOARD_PRODUCTS = 'RESET_DASHBOARD_PRODUCTS';
export const GET_DASHBOARD_PRODUCTS = 'GET_DASHBOARD_PRODUCTS';
export const SET_DASHBOARD_PRODUCTS = 'SET_DASHBOARD_PRODUCTS';
export const SET_PRODUCT_LIST_NAME = 'SET_PRODUCT_LIST_NAME';
export const SET_BRAND_LIST_NAME = 'SET_BRAND_LIST_NAME';
export const SET_DASHBOARD_PRODUCTS_ERROR = 'SET_DASHBOARD_PRODUCTS_ERROR';
export const GET_DASHBOARD_PRODUCTS_FILTER = 'GET_DASHBOARD_PRODUCTS_FILTER';
export const SET_DASHBOARD_PRODUCTS_FILTER = 'SET_DASHBOARD_PRODUCTS_FILTER';
export const SET_DASHBOARD_PRODUCTS_FILTER_ERROR = 'SET_DASHBOARD_PRODUCTS_FILTER_ERROR';

export const FETCH_PRODUCTS_BEGIN = 'FETCH_PRODUCTS_BEGIN';
export const RECEIVE_PRODUCTS = 'RECEIVE_PRODUCTS';
export const FETCH_SINGLE_PRODUCT = 'FETCH_SINGLE_PRODUCT';

// FILTERS
export const RESET_FILTER_AND_SORTING = 'RESET_FILTER_AND_SORTING';
export const FILTER_BRAND = 'FILTER_BRAND';
export const FILTER_COLOR = 'FILTER_COLOR';
export const FILTER_PRICE = 'FILTER_PRICE';
export const SORT_BY = 'SORT_BY';

// CURRENCY
export const CHANGE_CURRENCY = 'CHANGE_CURRENCY';

// Cart
export const FETCH_CART_DATA = 'FETCH_CART_DATA';
export const SET_CART_DATA = 'SET_CART_DATA';
export const SET_CART_DATA_FULL = 'SET_CART_DATA_FULL';
export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const INCREMENT_QTY = 'INCREMENT_QTY';
export const DECREMENT_QTY = 'DECREMENT_QTY';
export const UPDATE_WITH_DELIVERY_SLOTS = 'UPDATE_WITH_DELIVERY_SLOTS';
export const RESET_THE_CART = 'RESET_THE_CART';
export const APPLY_COUPON_DATA = 'APPLY_COUPON_DATA';

// Order
export const PLACE_ORDER = 'PLACE_ORDER';
export const PLACE_ORDER_SUCCESS = 'PLACE_ORDER_SUCCESS';
export const PLACE_ORDER_ERROR = 'PLACE_ORDER_ERROR';

// WishList
export const ADD_TO_WISHLIST = 'ADD_TO_WISHLIST';
export const REMOVE_FROM_WISHLIST = 'REMOVE_FROM_WISHLIST';

// Compare
export const ADD_TO_COMPARE = 'ADD_TO_COMPARE';
export const REMOVE_FROM_COMPARE = 'REMOVE_FROM_COMPARE';

// CheckOut Process
export const CHECKOUT_REQUEST = 'CHECKOUT_REQUEST';
export const CHECKOUT_SUCCESS = 'CHECKOUT_SUCCESS';
export const CHECKOUT_FAILURE = 'CHECKOUT_FAILURE';

// SIGNUP
export const SET_USER_SIGNUP_SUCCESS = 'SET_USER_SIGNUP_SUCCESS';
export const SET_USER_SIGNUP_ERROR = 'SET_USER_SIGNUP_ERROR';

// LOGOUT
export const LET_USER_LOGOUT = 'LET_USER_LOGOUT';

// PROFILE
export const LET_USER_EDIT_PROFILE = 'LET_USER_EDIT_PROFILE';

// ORDER
export const FETCH_ORDERS = 'FETCH_ORDERS';

//PAGES
export const SET_ABOUTUS_PAGE = 'SET_ABOUTUS_PAGE';
export const GET_ABOUTUS_PAGE = 'GET_ABOUTUS_PAGE';

//SETTING
export const GET_SETTINGS = 'GET_SETTINGS';
export const SET_SETTINGS = 'SET_SETTINGS';

//SETTING
export const GET_OFFERS = 'GET_OFFERS';
export const SET_OFFERS = 'SET_OFFERS';

//SERVICE
export const SET_SERVICE = 'SET_SERVICE';
export const GET_SERVICE = 'GET_SERVICE';

export const USER_LOGIN_DATA = 'USER_LOGIN_DATA';

export const UPDATE_PRODUCTS_IN_CART_COUNTER = 'UPDATE_PRODUCTS_IN_CART_COUNTER';
