import React from 'react';
import { PhoneInput, removeDialCode } from 'react-international-phone';
import 'react-international-phone/style.css';

import ValidationError from '../ValidationError';

import './styles.scss';

const PhoneLocalInput = ({ value, onChange, placeholder, errors = [] }) => {
  return (
    <div className="phone-input">
      <PhoneInput
        defaultCountry={'it'}
        value={value}
        onChange={(phone, meta) => {
          const phoneWithoutDialCode = removeDialCode({ phone, dialCode: meta.country.dialCode });
          if (phoneWithoutDialCode.length) {
            onChange(phone, meta);
          } else {
            onChange(phoneWithoutDialCode, meta);
          }
        }}
        placeholder={placeholder}
      />
      {errors.length ? <ValidationError errorMessage={errors[0]} /> : null}
    </div>
  );
};

export default PhoneLocalInput;
