import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import axios from 'axios';

import { useModal } from '../../providers/ModalProvider';
import PrimaryButton from '../Buttons/PrimaryButton/PrimaryButton';

const ContactForm = () => {
  const { t, i18n } = useTranslation();
  const modal = useModal();

  const [errors, setErrors] = useState({});
  const [acceptPrivacyPolicy, setAcceptPrivacyPolicy] = useState(0);

  const handleSubmit = async (event) => {
    event.preventDefault();

    var data = new FormData();
    data.append('name', event.target['name'].value);
    data.append('surname', event.target['surname'].value);
    data.append('agency', event.target['agency'].value);
    data.append('email', event.target['email'].value);
    data.append('description', event.target['description'].value);
    data.append('subscribe_to_newsletter', event.target['subscribe_to_newsletter'].checked);

    setErrors({});

    axios({
      method: 'post',
      url: '/v1/contact-us',
      data: data,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then(function (response) {
        modal.showSuccess({
          subtitle: t('contact-form.successfully-submitted'),
        });
        event.target.reset();
      })
      .catch(function (err) {
        setErrors(err?.response?.data?.result?.errors || {});
      });
  };

  return (
    <div className="customer_service">
      <div className="customer_wrapper container">
        <div className="customer_content">
          <div className="tag_line">
            <h1>{t('contattaci')}</h1>
            <p>{t('siamo_a_tua_completa_disposizione')}</p>
          </div>
          <div className="form_section">
            <form onSubmit={handleSubmit}>
              <div className="form_control">
                <input type="text" name="name" placeholder={t('contact-form.name-required')} />
                {errors && errors.name ? <div className="error">{errors.name[0]}</div> : null}
              </div>
              <div className="form_control">
                <input type="text" name="surname" placeholder={t('contact-form.surname-required')} />
                {errors && errors.surname ? <div className="error">{errors.surname[0]}</div> : null}
              </div>
              <div className="form_control">
                <input type="text" name="agency" placeholder={t('agency')} />
                {errors && errors.agency ? <div className="error">{errors.agency[0]}</div> : null}
              </div>
              <div className="form_control">
                <input type="email" name="email" placeholder={t('common.email_required')} />
                {errors && errors.email ? <div className="error">{errors.email[0]}</div> : null}
              </div>
              <div className="form_control">
                <textarea name="description" rows="10" cols="50" placeholder={t('common.inserisci_il_tuo')}></textarea>
                {errors && errors.description ? <div className="error">{errors.description[0]}</div> : null}
              </div>
              <div className="form_action">
                <div className="checkbox">
                  <input id="subscribe_to_newsletter" type="checkbox" className="checkbox-box" />
                  <label htmlFor="subscribe_to_newsletter">{t('contact-form.subscribe-to-newsletter')}</label>
                </div>
              </div>
              <div className="form_action">
                <div className="checkbox">
                  <input
                    checked={acceptPrivacyPolicy}
                    onChange={(e) => {
                      setAcceptPrivacyPolicy(e.target.checked);
                    }}
                    type="checkbox"
                    className="checkbox-box"
                  />
                  <p>
                    <a href="https://www.iubenda.com/privacy-policy/55960526" target="_blank">
                      {t('common.privacy_policy')}
                    </a>
                  </p>
                </div>
                <PrimaryButton disabled={!acceptPrivacyPolicy}>{t('common.invia_messaggio')}</PrimaryButton>
              </div>
              <p style={{ color: 'darkred' }}>
                {acceptPrivacyPolicy === false ? t('Si prega di accettare la privacy') : ''}
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
