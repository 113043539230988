import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { contactPaths, productPaths } from '../../providers/TranslatedSlugProvider';
import { getTranslated } from '../../utils/translations';

const PageNotFound = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className="main_content container page-not-found">
      <div className="not-found">
        <h1>404</h1>
        <h2>{t('Oops! Pagina non trovata')}</h2>
        <p>
          {t(
            'Ci dispiace, ma la pagina che stai cercando potrebbe essere stata rimossa o non essere al momento disponibile.'
          )}
        </p>
        <p className="margin-top">{t('Per favore, controlla il tuo URL, o esplora il nostro sito visitando...')}</p>
        <div className="buttons">
          <Link to="/">
            <button>{t('pagina principale')}</button>
          </Link>
          <Link to={getTranslated(i18n, productPaths, { en: 'en', it: 'it' }, 'it')}>
            <button>{t('negozio')}</button>
          </Link>
          <Link to={getTranslated(i18n, contactPaths, { en: 'en', it: 'it' }, 'it')}>
            <button>{t('contatti')}</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
