export const getTranslated = (i18n, object, fields_by_language, default_field) => {
  const language = i18n.language;

  if (!object) {
    return null;
  }

  if (language in fields_by_language && fields_by_language[language] in object) {
    return object[fields_by_language[language]];
  }

  return default_field in object ? object[default_field] : null;
};

export const translateProblemType = (id) => {
  let name = '';

  switch (id) {
    case 'DIP/RC - Scorrev':
      name = 'Scorrevolezza';
      break;
    case 'FIL - Gira':
      name = 'Filato che gira';
      break;
    case 'FIL - Grovigli':
      name = 'Grovigli';
      break;
    case 'FIL - Inquinam':
      name = 'Inquinamento';
      break;
    case 'FIL - Resistenza':
      name = 'Resistenza bassa';
      break;
    case 'FIL - Titolo <>':
      name = 'Titolo diverso';
      break;
    case 'SPEDIZ. eccesso':
      name = 'Spedito in eccesso';
      break;
    case 'TINT - Barrat':
      name = 'Barratura';
      break;
    case 'TINT - Fuori tono':
      name = 'Fuori tono';
      break;
    case 'TINT - Solid colore':
      name = 'Solidità colore';
      break;
    default:
      break;
  }

  return name;
};
