import React, { useState } from 'react';

import classnames from 'classnames';

import Container from '../Container';

import './styles.scss';

const TabsComponent = ({ tabs = [], useContainerForHeader = false }) => {
  const [openTab, setOpenTab] = useState(tabs[0]);

  if (!(tabs.length > 0)) {
    return <></>;
  }

  const getHeader = () => (
    <div className="tabs-component__header">
      {tabs.map((tab) => (
        <div
          onClick={() => setOpenTab(tab)}
          className={classnames({ 'tabs-component__header__item-name': true, active: tab.name === openTab.name })}
        >
          {tab.name}
        </div>
      ))}
    </div>
  );

  return (
    <div className="tabs-component">
      {useContainerForHeader ? <Container>{getHeader()}</Container> : getHeader()}
      <div className="tabs-component__tabs-content">
        {tabs.map((tab) => (
          <div
            key={tab.name}
            className={classnames({ 'tabs-component__tabs-content__item': true, 'd-none': tab.name !== openTab.name })}
          >
            {tab.content}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TabsComponent;
