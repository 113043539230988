import { React } from 'react';
import { useTranslation } from 'react-i18next';

import { getCertificateName } from '../../../constants/certificates';

import CertificateIcon from './CertificateIcon/CertificateIcon';
import CertificateInfo from './CertificateInfo/CertificateInfo';

const CertificateSelector = ({ globalCertifications, certification, setCertification }) => {
  const { t, i18n } = useTranslation();

  return globalCertifications.length ? (
    <div className="product_info">
      <h5 className="details_title">{t('Certificazioni')}</h5>
      <p>{t("L'impiego di cotone biologico comporta un sovrapprezzo.")}</p>
      {globalCertifications.map((c) => (
        <div className="certificate-option">
          <label className="certificate">
            <input
              type="checkbox"
              checked={certification === c.YARNTYPEID}
              onChange={(e) => {
                if (e.target.checked) {
                  setCertification(c.YARNTYPEID);
                } else {
                  setCertification(null);
                }
              }}
            />
            <span className="checkbox" />
            {getCertificateName(c.YARNTYPEID)}
            <CertificateIcon YARNTYPEID={c.YARNTYPEID} />
          </label>
          <CertificateInfo YARNTYPEID={c.YARNTYPEID} />
        </div>
      ))}
    </div>
  ) : null;
};

export default CertificateSelector;
