import { React, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import axios from 'axios';
import classnames from 'classnames';

import { UPDATE_PRODUCTS_IN_CART_COUNTER } from '../../constants/ActionTypes';
import { SAMPLE_PRODUCT_TYPE } from '../../hooks/useSampleProducts';
import { useModal } from '../../providers/ModalProvider';
import { checkoutPaths, productPaths } from '../../providers/TranslatedSlugProvider';
import { formatPriceForView as localFormatPrice } from '../../utils/format';
import { useLoader } from '../../utils/hooks';
import { getTranslated } from '../../utils/translations';
import User from '../../utils/user';

import PSample from './components/PSample';
import Purchase from './components/Purchase';

const Cart = (props) => {
  const dispatch = useDispatch();
  const [Cartlist, Setcartlist] = useState({});
  const [totalForProducts, setTotalForProducts] = useState(0);
  const [totalSupplement, setTotalSupplement] = useState(0);
  const [timer, setTimer] = useState(null);
  const [show, Setshow] = useState(0);
  const userDataDetail = useSelector((state) => state?.auth?.userDataDetail);
  const loader = useLoader({ height: '30vh' }, true);
  const { t, i18n } = useTranslation();
  useEffect(() => {
    console.log('CART_LIST', Cartlist);
  }, [Cartlist]);
  const formatPriceForView = (amount) => localFormatPrice(amount, User.getCurrency(userDataDetail));

  const list = (id) => {
    Setshow(0);
    loader.watchPromise(
      axios
        .post('/v1/cart-list', {
          client_id: parseInt(userDataDetail?.id),
        })
        .then((response) => {
          dispatch({
            type: UPDATE_PRODUCTS_IN_CART_COUNTER,
            productsInCartCounter: response.data.result.data?.cartQuantity || 0,
          });
          Setshow(1);
          Setcartlist(response.data.result.data);
          // TODO: this probably will be moved to back end on upcoming refactor
          setTotalSupplement(
            (response.data.result.data?.carts || []).reduce((acc, item) => {
              return acc + (JSON.parse(item.is_available_json)?.suppliment || 0);
            }, 0)
          );
          setTotalForProducts(
            (response.data.result.data?.carts || []).reduce((acc, item) => {
              return (
                acc +
                ((parseFloat(item.product_price) + (JSON.parse(item.is_available_json)?.suppliment2 || 0)) *
                  item.quantity || 0)
              );
            }, 0)
          );
        })
    );
  };

  const fetchAndUpdateTotalPrice = () => {
    axios
      .post('/v1/cart-list', {
        client_id: parseInt(userDataDetail?.id),
      })
      .then((response) => {
        dispatch({
          type: UPDATE_PRODUCTS_IN_CART_COUNTER,
          productsInCartCounter: response.data.result.data?.cartQuantity || 0,
        });
        Setcartlist((s) => ({ ...s, cartTotal: response.data.result.data.cartTotal }));
        // TODO: this probably will be moved to back end on upcoming refactor
        setTotalSupplement(
          (response.data.result.data?.carts || []).reduce((acc, item) => {
            return acc + (JSON.parse(item.is_available_json)?.suppliment || 0);
          }, 0)
        );
        setTotalForProducts(
          (response.data.result.data?.carts || []).reduce((acc, item) => {
            return (
              acc +
              ((parseFloat(item.product_price) + (JSON.parse(item.is_available_json)?.suppliment2 || 0)) *
                item.quantity || 0)
            );
          }, 0)
        );
      });
  };

  const isSampleOrder = useMemo(() => {
    return Cartlist?.carts?.every((item) => item.product_type === SAMPLE_PRODUCT_TYPE);
  }, [Cartlist, SAMPLE_PRODUCT_TYPE]);

  const updateCartItem = (updated) => {
    Setcartlist((s) => ({
      ...s,
      carts: s.carts.map((purchase) => {
        if (purchase.id.toString() === updated.id.toString()) {
          purchase.quantity = updated.quantity;
          purchase.sub_total = updated.sub_total;
        }
        return purchase;
      }),
    }));
    fetchAndUpdateTotalPrice();
  };

  const modal = useModal();

  const updateCart = (data, key, value, showModal = 'show modal') => {
    const olddata = JSON.parse(data.is_available_json);

    const newdata = { ...olddata, [key]: value };

    const stringifyed = JSON.stringify(newdata);

    return axios
      .post(`/v1/update-cart/${data.id}`, {
        config_id: data?.config_id,
        color_id: data?.color_id,
        product_name: data?.product_name,
        product_price: data?.product_price,
        suppliment: newdata?.suppliment || 0,
        CERTIFICATESGROUP_CGK_19052: data?.CERTIFICATESGROUP_CGK_19052,
        certificate: data?.certificate,
        suppliment2: newdata?.suppliment2 || 0,
        quantity: data?.quantity,
        client_id: data?.client_id,
        is_available_json: stringifyed,
        precise_integer: data?.precise_integer,
        avelphysical: value,
        product_type: data?.product_type,
      })
      .then((response) => {
        if (showModal === 'show modal') {
          modal.showSuccess({ onClose: list });
          // Swal.fire(response.data.message, '', 'success').then(() => {
          //   list();
          // });
        }

        Setcartlist((s) => {
          let item = s.carts.find((i) => i.id === data.id);
          if (item) item.is_available_json = stringifyed;
          return { ...s };
        });
      });
  };

  useEffect(() => {
    document.getElementById('home_page_class').classList.remove('home_page');
  }, []);

  useEffect(() => {
    document.getElementById('home_page_class').classList.remove('home_page');
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    list();
  }, []);

  const additionalIvaPrice = (totalForProducts + totalSupplement) * User.getIvaAmount(userDataDetail);

  const ableToGoToCheckout = useMemo(() => {
    if (!Cartlist || !Cartlist.carts?.length) {
      return false;
    }

    if (Cartlist.carts.every((item) => item.product_type === SAMPLE_PRODUCT_TYPE)) {
      return true;
    }

    return Cartlist.cartTotal > 0;
  }, [Cartlist, SAMPLE_PRODUCT_TYPE]);

  const RemoveCart = (id) => {
    modal.showAttention({
      // title: 'Sei sicuro?',
      subtitle: t('popup.confirmDeleteCart'), //'Non sarai in grado di recuperare questo file immaginario!',
      cancelButtonText: 'No, cancel it!',
      confirmButtonText: t('popup.confirmCta'), // 'Yes, I am sure!',
      onConfirm: () => {
        loader.watchPromise(
          axios.delete('/v1/remove-from-cart/' + id).then((response) => {
            modal.showSuccess({
              subtitle: t('popup.confirmedDeleteCart'), // response.data.message,
              onClose: () => {
                list();
              },
              confirmButtonText: t('popup.cartConfirmedCta'),
            });

            // Swal.fire(response.data.message, '', 'success').then(() => {
            //   list();
            // });
          })
        );
      },
    });
    // swal({
    //   title: 'Sei sicuro?',
    //   text: 'Non sarai in grado di recuperare questo file immaginario!',
    //   icon: 'warning',
    //   buttons: ['No, cancel it!', 'Yes, I am sure!'],
    //   dangerMode: true,
    // }).then(function (isConfirm) {
    //   if (isConfirm) {
    //     loader.watchPromise(
    //       axios.delete('/v1/remove-from-cart/' + id).then((response) => {
    //         Swal.fire(response.data.message, '', 'success').then(() => {
    //           list();
    //         });
    //       })
    //     );
    //   }
    // });
  };

  return (
    <>
      <div className="main_content blog_detail pitti">
        <div className="container cart_page">
          <div>
            <h1 className="cart_page_title">{t('Il tuo ordine')}</h1>
          </div>
          <div className={classnames({ order_list_section: true, 'sample-order': isSampleOrder })}>
            <div className="cart_list">
              {!loader.asJsx && Cartlist?.carts?.length > 0 ? (
                <>
                  <div className="order_list_section_title">
                    <h4>{t('I tuoi articoli')}</h4>
                  </div>
                  {Cartlist?.carts?.map((item, key) =>
                    item.product_type === SAMPLE_PRODUCT_TYPE ? (
                      <PSample item={item} RemoveCart={RemoveCart} />
                    ) : (
                      <Purchase
                        item={item}
                        key={key}
                        updateCart={updateCart}
                        timer={timer}
                        setTimer={setTimer}
                        list={list}
                        formatPriceForView={formatPriceForView}
                        updateCartItem={updateCartItem}
                        RemoveCart={RemoveCart}
                      />
                    )
                  )}
                </>
              ) : loader.asJsx ? (
                loader.asJsx
              ) : (
                <div className="empty-cart-block">
                  <img src="/images/cart/empty-cart.png" alt="empty cart" />
                  <div className="title">{t('Non ci sono prodotti nel tuo carrello')}</div>
                  <div className="info">
                    {t(
                      'Sembra che tu non abbia aggiunto nulla al tuo carrello. Prosegui e esplora le categorie principali.'
                    )}
                  </div>
                  <Link to={getTranslated(i18n, productPaths, { en: 'en', it: 'it' }, 'it')}>
                    <button>{t('ACQUISTA ORA')}</button>
                  </Link>
                </div>
              )}
            </div>

            {ableToGoToCheckout ? (
              <div className="order_summary_section">
                {!isSampleOrder ? (
                  <>
                    <div className="order_summary_section_title">
                      <h4>{t('Riepilogo ordine')}</h4>
                    </div>
                    <div className="order_summary_details">
                      <div className="order_summary_details_text">
                        <p>{t('Totale articoli')}</p>
                        <p>{formatPriceForView(totalForProducts)}</p>
                      </div>
                      {/* <div className="order_summary_details_text">
                        <div>
                            <p>Spese di spedizione</p>
                            <span className="suggestion">Possono essere soggette a variazioni</span>
                        </div>
                        <p>60,00 €</p>
                    </div> */}
                      {!!totalSupplement && (
                        <div className="order_summary_details_text">
                          <p>{t('Supplemento')}</p>
                          <p>{formatPriceForView(totalSupplement)}</p>
                        </div>
                      )}
                      <div className="order_summary_details_text">
                        <p>{t('Sub-totale')}</p>
                        <p>{formatPriceForView(Cartlist?.cartTotal)}</p>
                      </div>
                      {!!additionalIvaPrice && (
                        <div className="order_summary_details_text">
                          <p>Iva 22%</p>
                          <p>{formatPriceForView(additionalIvaPrice)}</p>
                        </div>
                      )}
                      <div className="order_summary_details_text order_total">
                        <p>{t('Totale')}</p>
                        <p>{formatPriceForView(Cartlist?.cartTotal + additionalIvaPrice)}</p>
                      </div>
                    </div>
                  </>
                ) : null}
                <div className="order_proceed">
                  <Link className="back_cart_btn" to={getTranslated(i18n, checkoutPaths, { en: 'en', it: 'it' }, 'it')}>
                    {t('Procedi con l’ordine')}
                  </Link>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default Cart;
