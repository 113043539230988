import React from 'react';

import './styles.scss';

const AuthFormHeader = ({ title, description }) => {
  return (
    <div className="auth-form-header">
      <h2 className="title">{title}</h2>
      <p className="description">{description}</p>
    </div>
  );
};

export default AuthFormHeader;
