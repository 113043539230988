export const BIOFIL_YARNTYPEID = 'BIOFIL';
export const GOTS_YARNTYPEID = 'GOTS';
export const OCS_YARNTYPEID = 'OCS';

export const getCertificateName = (YARNTYPEID) => {
  if (YARNTYPEID === GOTS_YARNTYPEID) {
    return 'GOTS-Transaction certificate';
  }

  return YARNTYPEID;
};
