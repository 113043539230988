import React, { useEffect, useState } from 'react';

let color_list = [
  '#000000',
  '#C4C4C4',
  '#FF9D9D',
  '#ffffff',
  '#AD7850',
  '#DF1A1A',
  '#B12672',
  '#1078AA',
  '#EC801C',
  '#35B820',
];

function Colorpicker({ setFilterColor, color }) {
  let targetedListArray = color?.map((data) => '#' + data.c_hex);
  const [targetColor, setTargetColor] = useState('');

  function hexToRgb(hex) {
    const r = parseInt(hex.substring(1, 3), 16);
    const g = parseInt(hex.substring(3, 5), 16);
    const b = parseInt(hex.substring(5, 7), 16);
    return { r, g, b };
  }

  function rgbToHex(r, g, b) {
    return `#${r.toString(16)}${g.toString(16)}${b.toString(16)}`;
  }

  function euclideanDistance(color1, color2) {
    const rDiff = color1.r - color2.r;
    const gDiff = color1.g - color2.g;
    const bDiff = color1.b - color2.b;
    return Math.sqrt(rDiff ** 2 + gDiff ** 2 + bDiff ** 2);
  }

  const closeClore = (e) => {
    e.stopPropagation();

    document.getElementById('accordion_info_color').classList.remove('active');
    setTargetColor('');
  };

  useEffect(() => {
    const targetColorRgb = hexToRgb(targetColor);
    let nearestColors = [];
    let smallestDistance = 127;
    for (let i = 0; i < targetedListArray.length; i++) {
      const colorRgb = hexToRgb(targetedListArray[i]);
      console.log(colorRgb, 'crg');
      const distance = euclideanDistance(targetColorRgb, colorRgb);
      if (distance < smallestDistance) {
        smallestDistance = distance;
        nearestColors.push(targetedListArray[i]);
      }
      // else if (distance === smallestDistance) {
      //     nearestColors.push(targetedListArray[i]);
      // }
    }

    setFilterColor(nearestColors);
    let component = document.getElementById('accordation_2');
    // component ? component.style.maxHeight = component.scrollHeight + "px" : ""
    return () => {};
  }, [targetColor]);

  // console.log(color, 'color');
  return (
    <>
      <div className={`show_color`}>
        <ul>
          {!targetColor &&
            color_list?.map((singleColor) => (
              <li>
                <a href="javascript:void(0);">
                  <div
                    className="item_color"
                    onClick={(e) => {
                      e.stopPropagation();
                      setTargetColor(singleColor);
                    }}
                  >
                    <span className="color" style={{ background: `${singleColor}` }} />
                  </div>
                </a>
              </li>
            ))}
          {targetColor && (
            <li>
              <a href="javascript:void(0);">
                <div className="item_color" onClick={() => setTargetColor(singleColor)}>
                  <span className="color" style={{ background: `${targetColor}` }} />
                </div>
              </a>
            </li>
          )}

          <li className="close">
            <a href="javascript:void(0);" onClick={closeClore}></a>
          </li>
        </ul>
      </div>
    </>
  );
}

export default Colorpicker;
