import React from 'react';

import classnames from 'classnames';

import './styles.scss';

const SecondaryButton = ({ type, onClick, children, disabled, fullWidth = false }) => {
  return (
    <button
      type={type}
      onClick={onClick}
      className={classnames({ secondary: true, 'full-width': fullWidth })}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default SecondaryButton;
