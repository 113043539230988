import { React, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';

import axios from 'axios';
import Swal from 'sweetalert2';

import { useModal } from '../../../providers/ModalProvider';

const Slider_cms = (props) => {
  const [img, SetImg] = useState('');
  const { t, i18n } = useTranslation();
  const modal = useModal();

  {
    img
      ? Swal.fire({
          imageUrl: img,
          showConfirmButton: false,
          showCloseButton: true,
        }).then(function () {
          SetImg('');
        })
      : '';
  }

  var settings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2.5,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.5,
          //   initialSlide: 2
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.1,
        },
      },
    ],
  };

  return (
    <>
      <div className="news_section">
        <div className="news_wrapper">
          <div className="title">
            <h3>{props.title}</h3>
          </div>
          <div className="">
            <Slider {...settings}>
              {props.images.map((image, index) => (
                <div key={index}>
                  <div
                    className=""
                    onClick={() => {
                      SetImg(image);
                    }}
                  >
                    <img src={image} alt="Digital" />
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
};

export default Slider_cms;
