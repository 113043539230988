import { useEffect, useState } from 'react';

export const getPaymentByPaymentCode = (paymentMethods, paymentCode) => {
  if (!paymentCode || !(paymentMethods?.length > 0)) {
    return null;
  }

  return paymentMethods?.find((p) => {
    return p.code === paymentCode;
  });
};

export const usePaymentByCode = ({ paymentMethods, paymentCode, paymentTerms, paymentTermCode }) => {
  const [payment, setPayment] = useState(null);
  const [paymentTerm, setPaymentTerm] = useState(null);

  useEffect(() => {
    setPayment(getPaymentByPaymentCode(paymentMethods, paymentCode));
  }, [paymentMethods, paymentCode]);

  useEffect(() => {
    if (!paymentTermCode || !(paymentTerms?.length > 0)) {
      setPaymentTerm(null);
      return;
    }

    const paymentTerm = paymentTerms?.find((p) => {
      return p.code === paymentTermCode;
    });

    setPaymentTerm(paymentTerm);
  }, [paymentTerms, paymentTermCode]);

  return { payment, paymentTerm };
};
