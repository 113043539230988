import React from 'react';
import { useTranslation } from 'react-i18next';

import './styles.scss';

const SearchInput = ({ value, onChange, placeholder }) => {
  const { t } = useTranslation();

  return (
    <div className="search-input">
      <input value={value} onChange={onChange} type="text" placeholder={placeholder} />
      <span></span>
    </div>
  );
};

export default SearchInput;
