import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { USER_LOGIN_DATA } from '../../../../constants/ActionTypes';

import { ReactComponent as ChangeClientSVG } from './../../../../assets/svg/change-client.svg';
import { ReactComponent as CloseSVG } from './../../../../assets/svg/close.svg';

import './styles.scss';

const LoggedInAsClientStatus = () => {
  const dispatch = useDispatch();
  const userDataDetail = useSelector((state) => state?.auth?.userDataDetail);
  const agentDataDetail = useSelector((state) => state?.auth?.agentDataDetail);

  const { t } = useTranslation();

  const closeViewingClient = () => {
    localStorage.setItem('userDataDetail', JSON.stringify(agentDataDetail));
    dispatch({
      type: USER_LOGIN_DATA,
      userdetailData: agentDataDetail,
    });
    window.location.href = '/dashboard-list';
  };

  return (
    <div className="logged-in-as-client-status">
      <span>
        {t('agent.cliente')} <u>{userDataDetail?.NAMEALIAS || userDataDetail?.business_name}</u>
      </span>
      <div className="logged-in-as-client-status__actions">
        <div className="logged-in-as-client-status__actions__action" onClick={closeViewingClient}>
          <ChangeClientSVG />
        </div>
        <div className="logged-in-as-client-status__actions__action" onClick={closeViewingClient}>
          <CloseSVG />
        </div>
      </div>
    </div>
  );
};

export default LoggedInAsClientStatus;
