import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';

import { useCreditSituationList } from '../../../../hooks/useCreditSituationList';
import { getPaymentByPaymentCode } from '../../../../hooks/usePaymentByCode';
import { usePaymentMethods } from '../../../../hooks/usePaymentMethods';
import { formatPriceForView } from '../../../../utils/format';
import { getTranslated } from '../../../../utils/translations';
import Loader from '../../../Loader';
import Table from '../../../Table/Table';
import TableCell from '../../../Table/TableCell';
import TableRow from '../../../Table/TableRow';

const CreditSituationList = () => {
  const { isLoading, rows } = useCreditSituationList({});
  const { paymentMethods, paymentTerms } = usePaymentMethods({});
  const { t, i18n } = useTranslation();

  const columns = [
    { index: 'Invoice', title: t('Fattura') },
    { index: 'TransDate', title: t('Data') },
    { index: 'DueDate', title: t('Data di scadenza') },
    { index: 'CurrencyCode', title: t('Valuta') },
    { index: 'AmountCur', title: t('Importo') },
    { index: 'AmountMST', title: t('Saldo') },
    { index: 'PaymMode', title: t('Metodo di pagamento') },
  ];

  return isLoading ? (
    <Loader />
  ) : (
    <Table columns={columns}>
      {rows.length > 0 ? (
        rows.map((row, rowIndex) => (
          <TableRow key={rowIndex}>
            <TableCell>{row?.Invoice}</TableCell>
            <TableCell>
              <Moment date={row?.TransDate} format="DD/MM/YYYY" />
            </TableCell>
            <TableCell>
              <Moment date={row?.DueDate} format="DD/MM/YYYY" />
            </TableCell>
            <TableCell>{row?.CurrencyCode}</TableCell>
            <TableCell>{formatPriceForView(row?.AmountCur, row?.CurrencyCode)}</TableCell>
            <TableCell>{formatPriceForView(row?.AmountMST, row?.CurrencyCode)}</TableCell>
            <TableCell>
              {getTranslated(
                i18n,
                getPaymentByPaymentCode(paymentMethods, row?.PaymMode),
                { en: 'name_en', it: 'name_it ' },
                'name_it'
              )}
            </TableCell>
          </TableRow>
        ))
      ) : (
        <TableRow>
          <TableCell colSpan={columns.length}>{t('Nessun risultato trovato.')}</TableCell>
        </TableRow>
      )}
    </Table>
  );
};

export default CreditSituationList;
