import { SET_SETTINGS, GET_SETTINGS } from '../constants/ActionTypes';

const initialState = {};

const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SETTINGS: {
      return { ...state, ...action.settings };
    }
    default:
  }
  return state;
};
export default settingsReducer;
