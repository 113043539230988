import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import axios from 'axios';

import { useModal } from '../../../providers/ModalProvider';
import PrimaryButton from '../../Buttons/PrimaryButton/PrimaryButton';
import SecondaryButton from '../../Buttons/SecondaryButton/SecondaryButton';
import TextInput from '../../Inputs/TextInput';
import ValidationError from '../../Inputs/ValidationError';

import { getCookie, setCookie } from './utils';

import './styles.scss';

const SubscribeToNewsletterModal = () => {
  const COOKIE_NAME = 'f-subscribe-modal-showed';
  const COOKIE_VALUE = '1';

  const { t } = useTranslation();
  const modal = useModal();
  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);

  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [email, setEmail] = useState('');
  const [privacyPolicy, setPrivacyPolicy] = useState(0);

  const addCookie = () => {
    setCookie(COOKIE_NAME, COOKIE_VALUE, 7);
    setShowModal(false);
  };

  const handleCloseClick = () => addCookie();

  const handleSubmit = () => {
    if (!privacyPolicy) {
      setPrivacyPolicy(false);
      return;
    }

    axios
      .post('/v1/newsletter_add', {
        name: name,
        surname: surname,
        email: email,
      })
      .then(function (response) {
        modal.showSuccess({ subtitle: t('subscribe-to-newsletter-modal.successfully-submitted') });
        setEmail('');
        setName('');
        setSurname('');
        document.getElementById('newslatyer').reset();
        addCookie();
      })
      .catch(function (err) {
        if (err?.response?.data?.message) {
          modal.showAttention({ subtitle: err.response.data.message });
        }
        setErrors(err?.response?.data?.result?.errors || {});
      });
  };

  useEffect(() => {
    setTimeout(() => {
      setShowModal(true);
    }, 2000);
  }, []);

  if (!showModal || getCookie(COOKIE_NAME) === COOKIE_VALUE) {
    return;
  }

  return (
    <div className="f-modal">
      <div className="container">
        <div className="subscribe-to-news-form">
          <h2>{t('subscribe-to-newsletter-modal.title')}</h2>
          <p>{t('subscribe-to-newsletter-modal.description')}</p>
          <div className="name-block">
            <div>
              <TextInput
                value={name}
                errors={errors?.name}
                updateValue={(value) => setName(value)}
                placeholder={t('subscribe-to-newsletter-modal.name-required')}
              />
            </div>
            <div>
              <TextInput
                name="surname"
                errors={errors?.surname}
                value={surname}
                updateValue={(value) => setSurname(value)}
                placeholder={t('subscribe-to-newsletter-modal.surname-required')}
              />
            </div>
          </div>
          <div>
            <TextInput
              value={email}
              errors={errors?.email}
              updateValue={(value) => setEmail(value)}
              placeholder={t('subscribe-to-newsletter-modal.email-required')}
            />
          </div>
          <div>
            <input
              onChange={(e) => {
                setPrivacyPolicy((prevState) => !prevState);
              }}
              checked={privacyPolicy}
              type="checkbox"
              className="checkbox-box"
            />{' '}
            <a href="https://www.iubenda.com/privacy-policy/55960526" target="_blank">
              {t('common.privacy_policy')}
            </a>
            {privacyPolicy === false ? <ValidationError errorMessage={t('Si prega di accettare la privacy')} /> : null}
          </div>
          <div className="actions">
            <SecondaryButton onClick={handleCloseClick}>{t('subscribe-to-newsletter-modal.close')}</SecondaryButton>
            <PrimaryButton onClick={handleSubmit}>{t('subscribe-to-newsletter-modal.subscribe')}</PrimaryButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscribeToNewsletterModal;
