import React from 'react';
import { useTranslation } from 'react-i18next';

import { getTranslated } from '../../../utils/translations';
import SelectInput from '../../Inputs/SelectInput';
import TextInput from '../../Inputs/TextInput';

const AdditionalInformation = ({ className, formDetails, formData, handleFormDataChange, errors }) => {
  const { t, i18n } = useTranslation();

  return (
    <div className={className}>
      <SelectInput
        id="revenue"
        name="revenue"
        label={t('register.revenue')}
        value={formData.revenue}
        updateValue={(value) => handleFormDataChange('revenue', value)}
        options={(formDetails?.revenue || []).map((r) => ({
          value: r.key,
          label: getTranslated(i18n, r, { en: 'name_en', it: 'name_it ' }, 'name_it'),
        }))}
        errors={errors?.revenue}
      />
      <TextInput
        id="number_of_machines"
        name="number_of_machines"
        placeholder={t('register.number-of-machines')}
        value={formData.number_of_machines}
        updateValue={(value) => handleFormDataChange('number_of_machines', value)}
        errors={errors?.number_of_machines}
      />
      <SelectInput
        id="number_of_employees"
        name="number_of_employees"
        label={t('register.number-of-employees')}
        value={formData.number_of_employees}
        updateValue={(value) => handleFormDataChange('number_of_employees', value)}
        options={(formDetails?.number_of_employees || []).map((r) => ({
          value: r.key,
          label: getTranslated(i18n, r, { en: 'name_en', it: 'name_it ' }, 'name_it'),
        }))}
        errors={errors?.number_of_employees}
      />
      <SelectInput
        id="production_for"
        name="production_for"
        label={t('register.production-for')}
        value={formData.production_for}
        updateValue={(value) => handleFormDataChange('production_for', value)}
        options={(formDetails?.production_for || []).map((r) => ({
          value: r.key,
          label: getTranslated(i18n, r, { en: 'name_en', it: 'name_it ' }, 'name_it'),
        }))}
        errors={errors?.production_for}
      />
    </div>
  );
};

export default AdditionalInformation;
