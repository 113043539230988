import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getCertificateName } from '../../../constants/certificates';
import { formatPriceForView as localFormatPrice } from '../../../utils/format';
import User from '../../../utils/user';
import CertificateIcon from '../../product/Components/CertificateIcon/CertificateIcon';
import ProductImage from '../../product/Components/ProductImage';
import { CUSTOM_PRODUCT_IMAGE_SRC } from '../../product/custom_product';

const CheckoutPurchase = ({ item }) => {
  const dataUser = useSelector((state) => state?.auth?.userDataDetail);

  const { t, i18n } = useTranslation();
  const formatPriceForView = (amount) => localFormatPrice(amount, User.getCurrency(dataUser));

  return (
    <div className="articles_content">
      <div className="product_img">
        <ProductImage
          activeimage={item?.hex_image_url}
          activeColor={item?.hex}
          product_t_images={item?.is_custom_product ? [] : item?.products['0']?.product_t_images}
          product_image={item?.is_custom_product ? CUSTOM_PRODUCT_IMAGE_SRC : item?.products['0']?.product_image}
        />
      </div>
      <div className="articles_product_details">
        <div className="product_name">
          <h5>{item?.product_name}</h5>
          <p>
            {' '}
            {item?.products[0]?.composition} | {item?.products['0'].nm} | {item?.products['0'].ne} |{' '}
            {item?.products['0'].finezza || 'NA'}
          </p>
        </div>
        <div class="product_color">
          <h5>{t('color')}:</h5>
          <p>{item.color_id}</p>
        </div>

        {/* <div class="product_certificate">
                            <h5>Certificazione: </h5>
                            <p>Gots - 0 €</p>
                        </div> */}

        {/* <div class="product_availability">
                            <h5>Disponibilità: </h5>
                            <p>Immediata - bagno unico</p>
                        </div> */}
        {item.certificate ? (
          <div className="product_total">
            <h5>{t('Certificazioni')}</h5>
            <h5 className="certificate">
              {getCertificateName(item.certificate)} <CertificateIcon YARNTYPEID={item.certificate} />
            </h5>
          </div>
        ) : null}
        <div className="product_price">
          <h5>{t('Prezzo un.')}</h5>
          <p>
            {formatPriceForView(
              parseFloat(item?.product_price) + (JSON.parse(item.is_available_json)?.suppliment2 || 0)
            )}
          </p>
        </div>
        <div>
          <h5>{t('Quantità')}</h5>
          <p>{item?.quantity}</p>
        </div>
        <div className="product_total">
          <h5>{t('Supplemento')}</h5>
          <h5>{formatPriceForView(JSON.parse(item.is_available_json)?.suppliment || 0)}</h5>
        </div>
        <div className="product_total">
          <h5>{t('Totale')}</h5>
          <h5>{formatPriceForView(item?.sub_total)}</h5>
        </div>
      </div>
    </div>
  );
};

export default CheckoutPurchase;
