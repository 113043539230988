import { React, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import axios from 'axios';
import swal from 'sweetalert';

import { useModal } from '../../../providers/ModalProvider';
import ContactForm from '../../ContactForm';

import SimpleMap from './map';

const Contact = () => {
  const [page, Setpage] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const { t, i18n } = useTranslation();
  const [socialMedia, SetsocialMedia] = useState([]);

  const q = searchParams.get('q');
  const src = searchParams.get('slug');
  const f = searchParams.get('f');

  const navigate = useNavigate();

  useEffect(() => {
    document.getElementById('home_page_class').classList.remove('home_page');

    axios.get('/v1/social-media').then((response) => {
      if (isNaN(response.data.result.data)) SetsocialMedia([...response.data.result.data]);
    });
  }, []);

  return (
    <div class="main_content">
      <div class="contact_section container">
        <div class="contact_wrapper">
          <h3>{t('contatti')}</h3>
        </div>
        <div class="contact_data">
          <div class="contact_item address_field">
            <h3>Filmar S.P.A.</h3>
            <p class="address">Via De Gasperi 6525030 Zocco d’Erbusco (BS) Italia</p>
            <p class="teliphone">
              <a href="tel:+39 030 776700">Tel: +39 030 776700</a>
            </p>
            <p class="fax">
              <a href="fax:+39 030 7760123">Fax: +39 030 7760123</a>
            </p>
            <div className=" footer_links contact">
              <div className="social">
                <ul>
                  <li>
                    <a href={socialMedia[0]?.facebook_link} target="_blank" className="facebook" />
                  </li>
                  <li>
                    <a href={socialMedia[0]?.instagram_link} target="_blank" className="instagram" />
                  </li>
                  <li>
                    <a href={socialMedia[0]?.linkedin_link} target="_blank" className="youtube" />
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="contact_item admin_field">
            <h3>{t('ufficio_amministrazione')}</h3>
            <div class="content_field">
              <div class="data_field">
                <h4>{t('amedeo_rossi')}</h4>
                <p>{t('amministrazione_e_finanza')}</p>
                <p>
                  <a href="mailto:a.rossi@filmar.it">a.rossi@filmar.it</a>
                </p>
              </div>
              <div class="data_field">
                <h4>{t('cristina_uberti')}</h4>
                <p>{t('contabilita')}</p>
                <p>
                  <a href="mailto:cristina@filmar.it">cristina@filmar.it</a>
                </p>
              </div>
            </div>
          </div>
          <div class="contact_item commerciale_field">
            <h3>{t('ufficio_commerciale')}</h3>
            <div class="item_data content_field">
              <div class="data_field">
                <h4>{t('simone_zanni')}</h4>
                <p>{t('direttore_vendite')}</p>
                <p>
                  <a href=" mailto:a.rossi@filmar.it">simone@filmar.it</a>
                </p>
              </div>
              <div class="data_field">
                <h4>{t('piera_francesca_solinas')}</h4>
                <p>{t('corporate_social_responsability')}</p>
                <p>
                  <a href="mailto:pierafrancesca.solinas@filmar.it">pierafrancesca.solinas@filmar.it</a>
                </p>
              </div>
              <div class="data_field">
                <h4>{t('michelle_marzoli')}</h4>
                <p>{t('marketing_communication_manager')}</p>
                <p>
                  <a href="mailto:michelle.marzoli@filmar.it">michelle.marzoli@filmar.it</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ContactForm />
      <div class="map_section">
        <div class="map_wrapper container">
          {/* <img src="/images/map_mobile.png" class="mobile_img" alt="mobile_map" /> */}
          <SimpleMap />
        </div>
      </div>
    </div>
  );
};

export default Contact;
