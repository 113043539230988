import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import axios from 'axios';

export const usePaymentMethods = ({}) => {
  const dataUser = useSelector((state) => state?.auth?.userDataDetail);

  const [isLoading, setIsLoading] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [paymentTerms, setPaymentTerms] = useState([]);

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    setIsLoading(true);

    axios
      .get('/v1/payment-methods', {
        headers: {
          Authorization: `Bearer ${dataUser.token}`,
        },
        signal,
      })
      .then((res) => {
        setPaymentMethods(res?.data?.result?.data?.paymentMethod || []);
        setPaymentTerms(res?.data?.result?.data?.paymentTerms || []);
      })
      .catch((error) => {
        console.error('error', error);
        setPaymentMethods([]);
      })
      .finally(() => setIsLoading(false));

    return () => abortController.abort();
  }, []);

  return {
    isLoading,
    paymentMethods,
    paymentTerms,
  };
};
