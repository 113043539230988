import { React, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import axios from 'axios';

import { TranslatedSlugContext } from '../../../providers/TranslatedSlugProvider';

import Slider_cms from './slider';

const CMSpages = (props) => {
  const [loading, setLoading] = useState(true);
  const slugContext = useContext(TranslatedSlugContext);
  const [page, setPage] = useState('');
  const [images, setImages] = useState([]);

  const { slug } = useParams();

  useEffect(() => {
    document.getElementById('home_page_class').classList.remove('home_page');
    axios
      .get('/v1/get-cms?slug=' + slug)
      .then((response) => {
        if (isNaN(response.data.result.data.content)) {
          setPage('<div>' + response.data.result.data.content + '</div>');

          slugContext.updateSlug(slug, {
            en: response.data.result.data.slug_en,
            it: response.data.result.data.slug_it,
          });

          setImages([...response.data.result.data.cms_images_url]);
        } else {
          slugContext.clearSlug();
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    return () => slugContext.clearSlug();
  }, []);

  return (
    <>
      {loading ? (
        <div className="loader_wrapper">
          <div className="loader"></div>
        </div>
      ) : (
        <>
          <div className="" dangerouslySetInnerHTML={{ __html: page }}></div>
          {images.length > 0 && (
            <div className="seller_section container">
              <Slider_cms images={images} />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default CMSpages;
