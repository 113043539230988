import { React, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import axios from 'axios';
import Swal from 'sweetalert2';

import { useModal } from '../../providers/ModalProvider';

function Customerservice(props, details, config_id, cID, product_name, client_id) {
  const [errors, setErrors] = useState({});

  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [email, setEmail] = useState('');
  const [detail, setDetail] = useState('');
  const [privacyPolicy, setPrivacyPolicy] = useState(0);
  const [subscribeToNewsletter, setSubscribeToNewsletter] = useState(false);

  const modal = useModal();

  const sendEnquiry = () => {
    setErrors({});

    if (privacyPolicy == 0) {
      modal.showAttention({ subtitle: `accettare l'informativa sulla privacy.` });
    } else {
      axios
        .post('/v1/send-enquiry', {
          name: name,
          surname: surname,
          email: email,
          detail: detail,
          subscribe_to_newsletter: subscribeToNewsletter,
          product_name: props.product_name,
          product_id: props.config_id,
          color_name: props.cID,
          color_id: props.client_id,
        })
        .then((response) => {
          modal.showSuccess({
            subtitle: t('get-information-form.successfully-submitted'),
          });
        })
        .catch(function (err) {
          setErrors(err?.response?.data?.result?.errors || {});
        });
    }
  };

  const { t, i18n } = useTranslation();
  return (
    <>
      <div className="customer_service container" id="request-information">
        <div className="customer_wrapper">
          <div className="customer_content">
            <div className="tag_line">
              {/*<div className="tag_img">
                                <img src="/images/product/2023.jpg" alt="Filmar_2023" />
                                <div className="content">
                                    <span>{t("artic_trovalo")}: </span>
                                    <p>
                                        <b>{t("a_pagina_12")} </b>
                                    </p>
                                    <p>
                                        <b>{t("del_catalogo_2022_2023")}</b>
                                    </p>
                                </div>
                            </div>*/}
              <div className="links">
                <a target="_blank" href={'https://filmar.it/media/2023_book_ss24_digitale_web.pdf'}>
                  {t('General Book 2024')}
                </a>
              </div>
              <div className="links">
                <a target="_blank" href={'https://www.filmar.it/media/2023_book_aw_24-25_digital_web.pdf'}>
                  {t('autunno inverno 2024 - 2025')}
                </a>
              </div>
              <div className="links">
                {/* <a href="#">{t("scarica_catalogo")}</a> */}
                {props?.details?.p_url ? (
                  <a target="_blank" href={props?.details?.p_url || '#'}>
                    {t('scarica_cartella_colore_edizione_6')}
                  </a>
                ) : (
                  ''
                )}
              </div>
            </div>
            <div className="form_section ">
              <h3>{t('richiedi_campionatura_su_questa_collezione')}</h3>
              <form action="#" method="POST">
                <div className="form_control">
                  <input
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    type="text"
                    name="name"
                    placeholder={t('get-information-form.name-required')}
                  />
                  {errors && errors.name ? <div className="error">{errors.name[0]}</div> : null}
                </div>
                <div className="form_control">
                  <input
                    onChange={(e) => {
                      setSurname(e.target.value);
                    }}
                    type="text"
                    name="surname"
                    placeholder={t('get-information-form.surname-required')}
                  />
                  {errors && errors.surname ? <div className="error">{errors.surname[0]}</div> : null}
                </div>
                <div className="form_control">
                  <input
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    type="email"
                    name="email"
                    placeholder={t('common.email_required')}
                  />
                  {errors && errors.email ? <div className="error">{errors.email[0]}</div> : null}
                </div>
                <div className="form_control">
                  <textarea
                    onChange={(e) => {
                      setDetail(e.target.value);
                    }}
                    name="message"
                    rows={10}
                    cols={50}
                    placeholder={t('common.inserisci_il_tuo')}
                    defaultValue={''}
                  />
                  {errors && errors.detail ? <div className="error">{errors.detail[0]}</div> : null}
                </div>
                <div className="form_action">
                  <div className="checkbox">
                    <input
                      id="subscribe_to_newsletter"
                      type="checkbox"
                      className="checkbox-box"
                      onChange={(e) => setSubscribeToNewsletter(e.target.checked)}
                    />
                    <label htmlFor="subscribe_to_newsletter">{t('get-information-form.subscribe-to-newsletter')}</label>
                  </div>
                </div>
                <div className=" form_action">
                  <div className="checkbox">
                    <input
                      onChange={(e) => {
                        setPrivacyPolicy(!privacyPolicy);
                      }}
                      type="checkbox"
                      className="checkbox-box"
                    />
                    <p>
                      <a href="https://www.iubenda.com/privacy-policy/55960526" target="_blank">
                        {t('common.privacy_policy')}
                      </a>
                    </p>
                  </div>
                  <button
                    style={{ background: '#455560', color: 'white' }}
                    className="button_action"
                    type="button"
                    onClick={(e) => {
                      sendEnquiry();
                    }}
                  >
                    {t('common.invia_messaggio')}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Customerservice;
