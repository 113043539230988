import { React, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import axios from 'axios';
import Swal from 'sweetalert2';

import { useModal } from '../../providers/ModalProvider';
import { formatPriceForView, formatQuantityForView } from '../../utils/format';
import { useLoader } from '../../utils/hooks';
import { getTranslated, translateProblemType } from '../../utils/translations';
import User from '../../utils/user';
import { CUSTOM_PRODUCT_IMAGE_SRC } from '../product/custom_product';

const ticketDetail = (lodemore) => {
  const { t } = useTranslation();
  const params = useParams();
  const dataUser = useSelector((state) => state?.auth?.userDataDetail);

  const [ticketDetail, setTicketDetail] = useState([]);
  const [salesTable, setSalesTable] = useState([]);
  const [salesName, setSalesName] = useState(null);
  const [product, setProduct] = useState(null);
  const loader = useLoader({ height: 546 });

  const localFormatPriceForView = (amount) => {
    return formatPriceForView(amount, User.getCurrency(dataUser));
  };

  const { i18n } = useTranslation();

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  const getuserDataTicketDetails = () => {
    loader.watchPromise(
      axios
        .post(
          '/v1/ticket-detail',
          {
            customerid: dataUser.ACCOUNTNUM,
            ticketid: params.id,
          },
          {
            headers: {
              Authorization: `Bearer ${dataUser.token}`,
            },
          }
        )
        .then((res) => {
          let InventNonConformanceTable = null;
          res?.data?.result?.data?.['InventNonConformanceTable'].map((val, i) => {
            if (val.InventNonConformanceID == params.id_ticket) InventNonConformanceTable = val;
          });
          if (InventNonConformanceTable) {
            setTicketDetail(InventNonConformanceTable);
            getuserDataOrderDetails(InventNonConformanceTable);
          }
        })
        .catch((error) => console.log('error', error))
    );
  };

  const getuserDataOrderDetails = (InventNonConformanceTable) => {
    loader.watchPromise(
      axios
        .post(
          '/v1/order-detail',
          {
            customerid: dataUser.ACCOUNTNUM,
            orderid: InventNonConformanceTable.InventRefId,
          },
          {
            headers: {
              Authorization: `Bearer ${dataUser.token}`,
            },
          }
        )
        .then((res) => {
          setSalesTable(res?.data?.result?.data?.['SalesTable'][0]);
          res?.data?.result?.data?.['SalesTable'][0]?.SalesLine?.map((val, i) => {
            if (val.ItemId == InventNonConformanceTable.ItemId) setSalesName(val.Name);
          });
          getProductDetail(InventNonConformanceTable.ItemId, InventNonConformanceTable.InventDim[0].InventColorId);
        })
        .catch((error) => console.log('error', error))
    );
  };

  const getProductDetail = (ItemId, InventColorId) => {
    axios
      .post(
        '/v1/product_detail/data',
        {
          id: ItemId,
          InventColorId: InventColorId,
        },
        {
          headers: {
            Authorization: `Bearer ${dataUser.token}`,
          },
        }
      )
      .then((response) => {
        // Handle the responses for each request
        setProduct(response?.data?.result?.data || null);
      })
      .catch((error) => {
        // Handle any errors that may occur during the requests
        console.error('An error occurred:', error);
      });
  };

  const modal = useModal();

  useEffect(() => {
    document.getElementById('home_page_class').classList.remove('home_page');
    getuserDataTicketDetails();
  }, []);

  return (
    <>
      <div className="" style={{ paddingBottom: 100 }}>
        <section className="order_detailss">
          <div className="container">
            <div className="order_number_main">
              <div className="order_number_detail">
                <p>
                  {t('Ticket')}
                  <span>#{ticketDetail?.InventNonConformanceID}</span>
                </p>
              </div>
              <div className="order_btn">
                <div className="return_btn">
                  <Link to={'/dashboard-list'}>{t('Torna alla lista dei ticket')}</Link>
                </div>
              </div>
            </div>
            <div className="order_pickup_detail">
              <div className="order_address">
                <p>
                  {t('Data creazione')}:{' '}
                  <span>
                    <Moment date={ticketDetail?.NonConformanceDate} format="DD/MM/YYYY" />
                  </span>
                </p>
                <p>
                  {t('Utente')}: <span>master</span>
                </p>
                <p>
                  {t('Stato')}: <span>{t(params.status)}</span>
                </p>
              </div>
            </div>

            {loader.asJsx || (
              <>
                <div className="order_detail_card" style={{ paddingTop: 20 }}>
                  <div className="details_section">
                    <div className="row_field main_address">
                      <div className="data">
                        <h3>{t('Informazioni ticket')}</h3>
                      </div>
                    </div>
                    <div className="row_field details">
                      <div className="data">
                        <label>{t('order_number')}</label>
                        <strong>{ticketDetail?.InventRefId}</strong>
                      </div>
                      <div className="data">
                        <label>{t('Data ordine')}</label>
                        <strong>
                          <Moment date={salesTable?.BFSalesDate} format="DD/MM/YYYY" />
                        </strong>
                      </div>
                      <div className="data">
                        <label>{t('Numero bagno')}</label>
                        <strong>{ticketDetail?.InventDim?.[0]?.InventBatchId}</strong>
                      </div>
                      <div className="data">
                        <label>{t('Codice colore')}</label>
                        <strong>{ticketDetail?.InventDim?.[0]?.InventColorId}</strong>
                      </div>
                    </div>
                    <div className="row_field details">
                      <div className="data">
                        <label>{t('Tipo di problema')}</label>
                        <strong>{t(translateProblemType(ticketDetail?.InventTestProblemTypeId))}</strong>
                      </div>
                      <div className="data">
                        <label>{t('Nome articolo')}</label>
                        <p className="article_name">
                          <strong>{product?.nome_articolo || ticketDetail?.InventDim?.[0].ConfigId} </strong>
                        </p>
                        <p>
                          <strong>
                            <span className="order_name">{product?.composition || salesName}</span>
                          </strong>
                        </p>
                        {product?.nm ? (
                          <p>
                            <strong>
                              <span className="order_num">
                                Nm {product?.nm} | Ne {product?.ne} | Finezza{product?.finezza}{' '}
                              </span>
                            </strong>
                          </p>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </section>
      </div>
    </>
  );
};
export default ticketDetail;
