import React from 'react';
import { useTranslation } from 'react-i18next';

import { BIOFIL_YARNTYPEID, GOTS_YARNTYPEID, OCS_YARNTYPEID } from '../../../../constants/certificates';
import Tooltip from '../../../shared/Tooltip/Tooltip';

const CertificateInfo = ({ YARNTYPEID }) => {
  const { t } = useTranslation();

  const text =
    YARNTYPEID === BIOFIL_YARNTYPEID
      ? t('biofil_tooltip_text')
      : YARNTYPEID === GOTS_YARNTYPEID
        ? t('gots_tooltip_text')
        : YARNTYPEID === OCS_YARNTYPEID
          ? t('ocs_tooltip_text')
          : null;

  return text ? <Tooltip text={text} /> : null;
};

export default CertificateInfo;
