import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import axios from 'axios';
import { useDebounce } from 'use-debounce';

export const useUsersByAgent = ({}) => {
  const dataUser = useSelector((state) => state?.auth?.userDataDetail);

  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);

  const filtersInitialState = {
    search: '',
  };
  const [filters, setFilters] = useState(filtersInitialState);

  const debouncedFilters = useDebounce(filters, 300);

  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [rows, setRows] = useState([]);

  const updateFilter = (filter, value) => {
    setFilters((prevState) => ({
      ...prevState,
      [filter]: value,
    }));
  };

  const resetFilter = () => {
    setFilters(filtersInitialState);
  };

  useEffect(() => {
    const { search } = debouncedFilters;

    const abortController = new AbortController();
    const signal = abortController.signal;
    setIsLoading(true);

    axios
      .get('/v1/get-users-by-agent', {
        params: {
          agent: dataUser.ACCOUNTNUM,
          query: search,
          page,
          perPage,
        },
        headers: {
          Authorization: `Bearer ${dataUser.token}`,
        },
        signal,
      })
      .then((res) => {
        setRows(res?.data?.result?.data?.data || []);
        setTotalCount(res?.data?.result?.data?.total);
      })
      .catch((error) => {
        console.error('error', error);
        setRows([]);
      })
      .finally(() => setIsLoading(false));

    return () => abortController.abort();
  }, [debouncedFilters, perPage, page]);

  return {
    isLoading,
    rows,
    page,
    setPage,
    filters,
    updateFilter,
    perPage,
    setPerPage,
    resetFilter,
    totalCount,
  };
};
